import { useSnackbar } from 'notistack';
import { useState } from 'react';
import { Link as RouterLink, useNavigate, useParams } from 'react-router-dom';
// @mui
import { alpha } from '@mui/material/styles';
import { Box, Divider, Grid, Typography, Stack, MenuItem } from '@mui/material';
// routes
import { PATH_DASHBOARD, PATH_AUTH, PATH_BET_HISTORY } from '../../../routes/paths';
// hooks
import useAuth from '../../../hooks/useAuth';
import useIsMountedRef from '../../../hooks/useIsMountedRef';
import useLocales from '../../../hooks/useLocales';
import useResponsive from '../../../hooks/useResponsive';
import useRedirect from '../../../hooks/useRedirect';

// components
import MyAvatar from '../../../components/MyAvatar';
import MenuPopover from '../../../components/MenuPopover';
import { IconButtonAnimate } from '../../../components/animate';
import Iconify from '../../../components/Iconify';
import Image from '../../../components/Image';

// redux
import { useDispatch } from '../../../redux/store';
import { openChangePwd, openLogin } from '../../../redux/slices/authPages';

// ----------------------------------------------------------------------

export default function AccountPopover() {
  const navigate = useRedirect();

  const params = useParams();

  const dispatch = useDispatch();

  const { translate } = useLocales();

  const { user, logout, isAuthenticated } = useAuth();

  const isMountedRef = useIsMountedRef();

  const { enqueueSnackbar } = useSnackbar();

  const [open, setOpen] = useState(null);

  const isDesktop = useResponsive('up', 'lg');

  const handleOpen = (event) => {
    setOpen(event?.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };

  const handleLogout = async () => {
    try {
      await logout();
      navigate(PATH_AUTH.login, { replace: true });

      if (isMountedRef.current) {
        handleClose();
      }
    } catch (error) {
      console.error(error);
      enqueueSnackbar('Unable to logout!', { variant: 'error' });
    }
  };

  return (
    <>
      <Grid
        onClick={(e) => (isAuthenticated ? handleOpen(e) : navigate('/auth/login'))}
        item
        xs={12 / 5}
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          cursor: 'pointer',
          flexDirection: 'column',
        }}
      >
        <Image
          src={require('../../../assets/home/bottom_nav/profile.png')}
          sx={{ height: '20px', width: '20px', display: 'flex', justifyContent: 'center', '& .wrapper': {} }}
        />
        <Typography
          sx={{
            fontSize: '11px',
            fontFamily: 'sans-serif',
            whiteSpace: 'nowrap',
            color: '#fff',
            fontWeight: 600,
            marginTop: 0.5,
          }}
        >
          {translate('profile')}
        </Typography>
      </Grid>
      {/* <IconButtonAnimate
        onClick={handleOpen}
        sx={{
          p: 0.2,
          backgroundColor: 'background.default',
          ...(open && {
            '&:before': {
              zIndex: 1,
              content: "''",
              width: '100%',
              height: '100%',
              borderRadius: '50%',
              position: 'absolute',
              bgcolor: (theme) => alpha(theme.palette.grey[900], 0.8),
            },
          }),
        }}
      >
        
        <Iconify icon="mdi:user" />
      </IconButtonAnimate> */}

      <MenuPopover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        sx={{
          p: 0,
          pb: 0.5,
          mt: 1.5,
          ml: 0.75,
          minWidth: 200,
          width: 'unset',
          display: 'flex',
          flexDirection: 'column',
          '& .MuiMenuItem-root': {
            typography: 'body2',
            borderRadius: 0.75,
          },
        }}
        disabledArrow={true}
      >
        <Box>
          <Stack sx={{ my: 1.5, px: 2.5 }} spacing={1}>
            <Typography variant="subtitle2" noWrap>
              {user?.first_name}
            </Typography>
            {/* {!isDesktop && ( */}
            <Box sx={{ display: 'flex', alignItems: 'center', color: 'text.secondary' }}>
              {user?.username ? (
                <>
                  <Iconify icon="mdi:user" sx={{ mr: 0.5 }} />
                  <Typography variant="caption" noWrap>
                    {user?.username}
                  </Typography>
                </>
              ) : (
                ''
              )}
            </Box>
            {/* )} */}
            {/* {user?.email ? (
              <Box sx={{ display: 'flex', alignItems: 'center', color: 'text.secondary' }}>
                <Iconify icon="ic:baseline-email" sx={{ mr: 0.5 }} />
                <Typography variant="caption" noWrap>
                  {user?.email}
                </Typography>
              </Box>
            ) : (
              ''
            )} */}
          </Stack>
        </Box>

        <Divider sx={{ borderStyle: 'dashed', mb: 1 }} />

        {/* <Stack sx={{ p: 1 }}>
          {MENU_OPTIONS.map((option) => (
            <MenuItem key={option.label} to={option.linkTo} component={RouterLink} onClick={handleClose}>
              {option.label}
            </MenuItem>
          ))}
        </Stack>
        */}

        <MenuItem
          to={`/${params?.domain}/user/account`}
          component={RouterLink}
          onClick={handleClose}
          sx={{ mx: 1, display: 'inline-block' }}
        >
          {translate('account')}
        </MenuItem>

        {!user?.is_kyc_verified ? (
          <MenuItem
            to={`/${params?.domain}/user/kyc`}
            component={RouterLink}
            onClick={handleClose}
            sx={{ mx: 1, display: 'inline-block' }}
          >
            {translate('kyc_verification')}
          </MenuItem>
        ) : (
          ''
        )}

        <MenuItem
          to={`/${params?.domain}/user/banks`}
          component={RouterLink}
          sx={{ mx: 1, display: 'inline-block' }}
          onClick={handleClose}
        >
          {translate('bank_account')}
        </MenuItem>

        <MenuItem
          to={`/${params?.domain}/history`}
          component={RouterLink}
          onClick={handleClose}
          sx={{ mx: 1, display: 'inline-block' }}
        >
          {translate('transaction_history')}
        </MenuItem>

        <MenuItem
          to={`/${params?.domain}${PATH_BET_HISTORY.root}`}
          component={RouterLink}
          onClick={handleClose}
          sx={{ mx: 1, display: 'inline-block' }}
        >
          {translate('bet_history')}
        </MenuItem>

        <MenuItem
          to={`/${params?.domain}/referral`}
          component={RouterLink}
          sx={{ mx: 1, display: 'inline-block' }}
          onClick={handleClose}
        >
          {translate('referral')}
        </MenuItem>

        <Divider sx={{ borderStyle: 'dashed' }} />

        <MenuItem
          onClick={() => {
            handleClose();
            navigate('/user/change-password');
          }}
          sx={{ mx: 1, display: 'inline-block' }}
        >
          {translate('change_password')}
        </MenuItem>

        <MenuItem onClick={handleLogout} sx={{ mx: 1, display: 'inline-block' }}>
          {translate('logout')}
        </MenuItem>
      </MenuPopover>
    </>
  );
}
