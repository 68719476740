// @mui
import { styled } from '@mui/material/styles';
import { Container, Stack, Typography } from '@mui/material';
// routes
// sections
import { LoginForm } from '../../sections/auth/login';
import useLocales from '../../hooks/useLocales';

// ----------------------------------------------------------------------

const ContentStyle = styled(Stack)(({ theme }) => ({
  marginRight: 'auto',
  marginLeft: 'auto',
  justifyContent: 'center',
  alignItems: 'center',
  padding: theme.spacing(2),
}));

// ----------------------------------------------------------------------

export default function Login() {
  const { translate } = useLocales();

  return (
    <ContentStyle spacing={3}>
      <Typography variant="h4" fontWeight={900} sx={{ color: 'red' }}>
        {translate('login')}
      </Typography>

      <Container maxWidth="xs" sx={{ p: 0 }}>
        <LoginForm />
      </Container>
    </ContentStyle>
  );
}
