import { createSlice } from '@reduxjs/toolkit';
//
import { dispatch } from '../store';

const initialState = {
  isLoading: false,
  open: false,
  title: '',
  subTitle: '',
  onConfirm: null,
  onCancel: null,
  isInfo: false,
  isDanger: false,
  confirmText: 'Yes',
  cancelText: 'No',
  hasClose: true,
};

const slice = createSlice({
  name: 'confirm',
  initialState,
  reducers: {
    setLoading(state, action) {
      state.isLoading = action.payload;
    },
    setOpen(state, action) {
      state.open = action.payload;
    },
    setTitle(state, action) {
      state.title = action.payload;
    },
    setSubTitle(state, action) {
      state.subTitle = action.payload;
    },
    setOnConfirm(state, action) {
      state.onConfirm = action.payload;
    },
    setOnCancel(state, action) {
      state.onCancel = action.payload;
    },
    setIsInfo(state, action) {
      state.isInfo = action.payload;
    },
    setIsDanger(state, action) {
      state.isDanger = action.payload;
    },
    setConfirmText(state, action) {
      state.confirmText = action.payload;
    },
    setCancelText(state, action) {
      state.cancelText = action.payload;
    },
    setHasClose(state, action) {
      state.hasClose = action.payload;
    },
    resetConfirmState(state) {
      state.title = initialState.title;
      state.subTitle = initialState.subTitle;
      state.onConfirm = initialState.onConfirm;
      state.onCancel = initialState.onCancel;
      state.isLoading = initialState.isLoading;
      state.open = initialState.open;
      state.isInfo = initialState.isInfo;
      state.isDanger = initialState.isDanger;
      state.confirmText = initialState.confirmText;
      state.cancelText = initialState.cancelText;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const { setLoading, setOpen, resetConfirmState } = slice.actions;

export const openConfirm = ({
  title,
  onConfirm,
  onCancel,
  subTitle,
  isInfo = false,
  isDanger = false,
  confirmText = 'Yes',
  cancelText = 'No',
  hasClose = true,
}) => {
  try {
    if (title) dispatch(slice.actions.setTitle(title));
    if (subTitle) dispatch(slice.actions.setSubTitle(subTitle));
    if (onConfirm) dispatch(slice.actions.setOnConfirm(onConfirm));
    if (onCancel) dispatch(slice.actions.setOnCancel(onCancel));
    if (confirmText) dispatch(slice.actions.setConfirmText(confirmText));
    if (cancelText) dispatch(slice.actions.setCancelText(cancelText));
    dispatch(slice.actions.setHasClose(hasClose));
    dispatch(slice.actions.setIsInfo(isInfo));
    dispatch(slice.actions.setIsDanger(isDanger));
  } catch (error) {
    console.log(error);
    return;
  }
  dispatch(slice.actions.setOpen(true));
};
