import PropTypes from 'prop-types';
import { useMemo, useState } from 'react';
// @mui
import {
  Box,
  List,
  Badge,
  Divider,
  Typography,
  ListItemText,
  ListSubheader,
  ListItemButton,
  CircularProgress,
} from '@mui/material';
// utils
import { fToNow } from '../../../utils/formatTime';
// components
import Iconify from '../../../components/Iconify';
import Scrollbar from '../../../components/Scrollbar';
import MenuPopover from '../../../components/MenuPopover';
import { IconButtonAnimate } from '../../../components/animate';
import { useDispatch, useSelector } from '../../../redux/store';
import { getInbox, getUnreadInboxCount, setUnreadInboxCount } from '../../../redux/slices/lookup';
import useInterval from '../../../hooks/useInterval';
import useLocales from '../../../hooks/useLocales';

// ----------------------------------------------------------------------

export default function NotificationsPopover() {
  const { translate } = useLocales();

  const dispatch = useDispatch();

  const { unreadInboxCount, inbox, memberBalance } = useSelector((x) => x.lookup);

  const [isLoading, setIsLoading] = useState(false);

  const [open, setOpen] = useState(null);

  const handleOpen = (event) => {
    setIsLoading(true);
    const targetBtn = event.currentTarget;

    getInbox().finally(() => {
      setIsLoading(false);
      setOpen(targetBtn);
    });
  };

  const handleClose = () => {
    setOpen(null);
    dispatch(setUnreadInboxCount(0));
  };

  const unreadMessages = useMemo(() => inbox.filter((m) => !m.read), [inbox]);
  const readMessages = useMemo(() => inbox.filter((m) => m.read), [inbox]);

  useInterval(getUnreadInboxCount, 15000);

  return (
    <>
      <IconButtonAnimate
        color={open ? 'primary' : 'default'}
        onClick={handleOpen}
        disabled={isLoading}
        sx={{ p: 0.2, backgroundColor: 'background.default' }}
      >
        {isLoading ? (
          <CircularProgress size={'1rem'} />
        ) : (
          <Badge badgeContent={unreadInboxCount || 0} color="error">
            <Iconify icon="eva:bell-fill" />
          </Badge>
        )}
      </IconButtonAnimate>

      <MenuPopover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        sx={{ width: 360, p: 0, mt: 1.5, ml: 0.75 }}
        mainSx={{ zIndex: 2247483648 }}
        disabledArrow={true}
      >
        <>
          <Box sx={{ display: 'flex', alignItems: 'center', py: 2, px: 2.5 }}>
            <Box sx={{ flexGrow: 1 }}>
              <Typography variant="subtitle1">{translate('notifications')}</Typography>
              <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                {translate('you_have_x_unread_messages', { x: unreadInboxCount })}
              </Typography>
            </Box>
          </Box>

          <Divider sx={{ borderStyle: 'dashed' }} />

          <Scrollbar sx={{ maxHeight: '70vh' }}>
            {unreadMessages?.length ? (
              <List
                disablePadding
                subheader={
                  <ListSubheader disableSticky sx={{ py: 1, px: 2.5, typography: 'overline' }}>
                    {translate('new')}
                  </ListSubheader>
                }
              >
                {unreadMessages.map((message) => (
                  <NotificationItem
                    key={message.id}
                    notification={message}
                    currencyCode={memberBalance?.currencyCode}
                  />
                ))}
              </List>
            ) : (
              ''
            )}

            {readMessages?.length ? (
              <List
                disablePadding
                subheader={
                  unreadMessages?.length ? (
                    <ListSubheader disableSticky sx={{ py: 1, px: 2.5, typography: 'overline' }}>
                      Before that
                    </ListSubheader>
                  ) : null
                }
              >
                {readMessages.map((message) => (
                  <NotificationItem
                    key={message.id}
                    notification={message}
                    currencyCode={memberBalance?.currencyCode}
                  />
                ))}
              </List>
            ) : (
              ''
            )}
          </Scrollbar>
        </>
        {/* <Divider sx={{ borderStyle: 'dashed' }} /> */}

        {/* <Box sx={{ p: 1 }}>
          <Button fullWidth disableRipple>
            View All
          </Button>
        </Box> */}
      </MenuPopover>
    </>
  );
}

// ----------------------------------------------------------------------

NotificationItem.propTypes = {
  notification: PropTypes.shape({
    created: PropTypes.instanceOf(Date),
    id: PropTypes.string,
    read: PropTypes.bool,
    title: PropTypes.string,
    description: PropTypes.string,
    activity_type: PropTypes.string,
    // avatar: PropTypes.any,
  }),
};

function NotificationItem({ notification, currencyCode }) {
  const { avatar, title } = renderContent(notification, currencyCode);

  return (
    <ListItemButton
      sx={{
        py: 1.5,
        px: 2.5,
        mt: '1px',
        ...(!notification.read && {
          bgcolor: 'action.selected',
        }),
      }}
    >
      {/* <ListItemAvatar>
        <Avatar sx={{ bgcolor: 'background.neutral' }}>{avatar}</Avatar>
      </ListItemAvatar> */}
      <ListItemText
        primary={title}
        secondary={
          <Typography
            variant="caption"
            sx={{
              mt: 0.5,
              display: 'flex',
              alignItems: 'center',
              color: 'text.disabled',
            }}
          >
            <Iconify icon="eva:clock-outline" sx={{ mr: 0.5, width: 16, height: 16 }} />
            {fToNow(notification.created)}
          </Typography>
        }
      />
    </ListItemButton>
  );
}

// ----------------------------------------------------------------------

// function getTitle(title, currencyCode) {
//   const firstSpaceIndex = title.indexOf(' ');
//   const secondSpaceIndex = title.indexOf(' ', firstSpaceIndex + 1);

//   if (firstSpaceIndex !== -1) {
//     const extractedText = title.substring(firstSpaceIndex + 1, secondSpaceIndex);
//     return `${currencyCode} ${parseFloat(extractedText) * 1000} ${title.substring(secondSpaceIndex + 1)}`;
//   }
//   return '';
// }

function renderContent(notification, currencyCode) {
  const _title = notification?.title;
  // if (
  //   (currencyCode?.toUpperCase() === 'MMK' || currencyCode?.toUpperCase() === 'VND') &&
  //   notification?.activity_type === 'deposit'
  // ) {
  //   _title = getTitle(notification?.title, currencyCode);
  // }

  const title = (
    <Typography variant="subtitle2">
      {_title}
      {/* <Typography component="span" variant="body2" sx={{ color: 'text.secondary' }}>
        &nbsp; {noCase(notification.description)}
      </Typography> */}
    </Typography>
  );

  if (notification.type === 'order_placed') {
    return {
      avatar: (
        <img
          alt={notification.title}
          src="https://minimal-assets-api-dev.vercel.app/assets/icons/ic_notification_package.svg"
        />
      ),
      title,
    };
  }
  if (notification.type === 'order_shipped') {
    return {
      avatar: (
        <img
          alt={notification.title}
          src="https://minimal-assets-api-dev.vercel.app/assets/icons/ic_notification_shipping.svg"
        />
      ),
      title,
    };
  }
  if (notification.type === 'mail') {
    return {
      avatar: (
        <img
          alt={notification.title}
          src="https://minimal-assets-api-dev.vercel.app/assets/icons/ic_notification_mail.svg"
        />
      ),
      title,
    };
  }
  if (notification.type === 'chat_message') {
    return {
      avatar: (
        <img
          alt={notification.title}
          src="https://minimal-assets-api-dev.vercel.app/assets/icons/ic_notification_chat.svg"
        />
      ),
      title,
    };
  }
  return {
    avatar: notification.avatar ? <img alt={notification.title} src={notification.avatar} /> : null,
    title,
  };
}
