// ----------------------------------------------------------------------

export default function Input(theme) {
  return {
    MuiInputBase: {
      styleOverrides: {
        root: {
          '&.Mui-disabled': {
            '& svg': { color: theme.palette.text.disabled },
          },
        },
        input: {
          '&::placeholder': {
            opacity: 1,
            color: theme.palette.text.disabled,
          },
        },
      },
    },
    MuiInput: {
      styleOverrides: {
        underline: {
          '&:before': {
            borderBottomColor: theme.palette.grey[500_56],
          },
        },
      },
    },
    MuiFilledInput: {
      styleOverrides: {
        root: {
          backgroundColor: theme.palette.grey[500_12],
          '&:hover': {
            backgroundColor: theme.palette.grey[500_16],
          },
          '&.Mui-focused': {
            backgroundColor: theme.palette.action.focus,
          },
          '&.Mui-disabled': {
            backgroundColor: theme.palette.action.disabledBackground,
          },
        },
        underline: {
          '&:before': {
            borderBottomColor: theme.palette.grey[500_56],
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          '& .MuiOutlinedInput-notchedOutline': {
            border: 'none',
          },
          '& .MuiInputAdornment-positionStart': {
            background: 'linear-gradient(0deg, rgba(201,201,201,1) 0%, rgba(246,246,246,1) 80%)',
            padding: '20px 10px',
            boxShadow: '0px 0px 2px 0px rgba(0,0,0,0.75) inset',
            borderTopLeftRadius: '18px',
            borderBottomLeftRadius: '18px',
          },
          background: 'white',
          borderRadius: '18px',
          color: 'black',
          // '&.Mui-disabled': {
          //   '& .MuiOutlinedInput-notchedOutline': {
          //     borderColor: theme.palette.action.disabledBackground,
          //   },
          // },
        },
      },
    },
  };
}
