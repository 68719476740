import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router';
import { Autocomplete, Box, Button, CardContent, InputAdornment, TextField } from '@mui/material';
import parsePhoneNumberFromString from 'libphonenumber-js';
import { countries } from '../_mock';
import Iconify from './Iconify';
import CustomModal from './CustomModal';
import { DEFAULT_COUNTRY_SELECTIONS, IS_DEV } from '../config';
import useLocales from '../hooks/useLocales';

const getCountryCodeObj = (code) => {
  if (!code) return null;
  let countryCode = null;
  if (code) {
    const foundCode = countries.find((x) => x?.code === code);
    if (foundCode) countryCode = foundCode;
  }

  return countryCode;
};

const PhonenumberTextField = ({
  onChange,
  value,
  showCountrySelections = true,
  isValidPhone = () => {},
  ...others
}) => {
  const [country, setCountry] = useState(null);
  const [textFieldValue, setTextFieldValue] = useState(value || '');

  const { translate } = useLocales();

  const params = useParams();

  const [openCodeSelect, setOpenCodeSelect] = useState(false);

  useEffect(() => {
    let _countryCode = process.env.REACT_APP_SUB_DOMAINS ? process?.env?.REACT_APP_SUB_DOMAINS?.split(',')[0] : [];

    if (params?.domain) {
      _countryCode = params.domain.toUpperCase().substring(0, 2);
    }

    const _country = getCountryCodeObj(_countryCode);

    if (_country) setCountry(_country);
  }, [params]);

  useEffect(() => {
    if (value) {
      const phoneNumber = parsePhoneNumberFromString(value, country?.code);

      if (phoneNumber) {
        if (IS_DEV) isValidPhone(phoneNumber.isPossible());
        else isValidPhone(phoneNumber.isValid());

        const _country = countries.find((c) => c.phone === phoneNumber.countryCallingCode);
        if (_country && _country.code !== country?.code) {
          setCountry(_country);
        }
        setTextFieldValue(phoneNumber.number);
      } else {
        isValidPhone(false);
        setTextFieldValue(value);
      }
    } else {
      isValidPhone(false);
      setTextFieldValue(`${country ? `+${country.phone}` : ''}${value}`);
    }
  }, [value, country]);

  const handleCountryChange = (country) => {
    handleCloseMenu();
    setCountry(country);
    if (country) {
      onChange(`+${country.phone}`);
    }
  };

  const handleTextFieldChange = (event) => {
    const phone = event.target.value;
    const phoneNumber = parsePhoneNumberFromString(phone, country?.code);

    if (phoneNumber) {
      onChange(phoneNumber.number);
    } else {
      onChange(phone);
    }
  };

  // const [openMenu, setOpenMenuActions] = useState(null);

  // const handleOpenMenu = (event) => {
  //   setOpenMenuActions(event.currentTarget);
  // };

  // const handleCloseMenu = () => {
  //   setOpenMenuActions(null);
  // };

  const handleOpenMenu = () => {
    setOpenCodeSelect(true);
  };

  const handleCloseMenu = () => {
    setOpenCodeSelect(false);
  };

  return (
    <>
      <TextField
        value={textFieldValue}
        size="small"
        sx={{ position: 'relative', color: 'grey !important' }}
        InputProps={{
          startAdornment: showCountrySelections ? (
            <InputAdornment position="start" sx={{ position: 'relative', color: 'grey', px: '4px !important' }}>
              <Button
                variant="text"
                size="small"
                onClick={handleOpenMenu}
                sx={{ m: 0, minWidth: 'unset' }}
                tabIndex={-1}
              >
                <Iconify icon="ic:baseline-phone" sx={{ fontSize: '20px', color: 'red', mr: 0.5 }} />
                {country?.code}
                <Iconify icon={'mingcute:down-fill'} />
              </Button>
              {/* +{country?.phone} */}
            </InputAdornment>
          ) : (
            ''
          ),
        }}
        {...others}
        onChange={handleTextFieldChange}
      />
      <CustomModal
        fullWidth
        maxWidth="xs"
        title={translate('Select Country Code')}
        open={openCodeSelect}
        onClose={handleCloseMenu}
        sx={{ border: '1px solid #dd0000' }}
      >
        <CardContent>
          <Autocomplete
            // disablePortal
            fullWidth
            value={country}
            options={
              DEFAULT_COUNTRY_SELECTIONS && DEFAULT_COUNTRY_SELECTIONS?.length
                ? countries.filter((x) => DEFAULT_COUNTRY_SELECTIONS.includes(x?.code))
                : countries
            }
            getOptionLabel={(option) => `${option.label} +${option.phone}`}
            onChange={(e, v) => handleCountryChange(v)}
            renderInput={(params) => <TextField fullWidth {...params} />}
            sx={{ bgcolor: '#fff', color: '#000', borderColor: '#dd0000', borderRadius: 1 }}
          />
        </CardContent>
      </CustomModal>
    </>
  );
};

PhonenumberTextField.propTypes = {};

export default PhonenumberTextField;
