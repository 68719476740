// ----------------------------------------------------------------------

// IF THIS TRANSLATION IS INCORRECT PLEASE IGNORE THIS AS THIS TRANSLATION IS FOR DEMO PURPOSES ONLY
// We are happy if you can help improve the translation by sending an email to support@minimals.cc.

// ----------------------------------------------------------------------

const hi = {
  // login
  login: 'लॉगिन',

  // register
  register: 'रजिस्टर',

  // home
  game_partners: 'गेम साझेदार',
  responsible_gaming: 'जिम्मेदार गेमिंग',
  supported_bank: 'समर्थित बैंक',
  load_more: 'अधिक लोड करें',

  // game
  choose_your_favourite_x_games: '{{x}} अपने पसंदीदा खेल चुनें',
  choose_your_favourite_x: '{{x}} अपना पसंदीदा चुनें',
  launch: 'लॉन्च',

  // notification
  notifications: 'सूचनाएँ',
  you_have_x_unread_messages: 'आपके पास {{x}} अपठित संदेश हैं',

  // deposit/withdraw
  drop_or_select_file: 'फ़ाइल ड्रॉप करें या चुनें',
  drop_files_here: 'फ़ाइलें यहाँ ड्रॉप करें',
  bank: 'बैंक',
  bank_name: 'बैंक का नाम',
  account_name: 'खाता नाम',
  account_number: 'खाता संख्या',
  bank_account: 'बैंक खाता',
  something_went_wrong: 'कुछ गलत हो गया। कृपया सहायता से संपर्क करें।',
  deposit_amount_between_x_and_y: 'राशि {{x}} और {{y}} के बीच होनी चाहिए',
  minimum_amount_is_x_y: 'न्यूनतम राशि {{x}}{{y}} है',
  payment_method: 'भुगतान विधि',
  mode: 'मोड',
  transfer_to: 'स्थानांतरित करें',
  select: 'चुनें',
  account: 'खाता',
  amount: 'राशि',
  deposit: 'जमा',
  withdraw: 'निकासी',
  amount_no_exceed_balance: 'निकासी राशि उपलब्ध शेष राशि से अधिक नहीं होनी चाहिए',
  minimum_withdraw_amount_is_x: 'न्यूनतम निकासी राशि {{x}} है',
  maximum_withdraw_amount_is_x: 'अधिकतम निकासी राशि {{x}} है',
  available_balance: 'उपलब्ध शेष राशि',
  amount_withdrawn: 'निकाली गई राशि',
  no_bank_account: 'आपके पास निकासी के लिए कोई खाता नहीं है, अब जोड़ें',
  add_account: 'खाता जोड़ें',
  account_name_must_be_4: 'खाता नाम 4 से अधिक लंबाई में होना चाहिए',
  please_select_bank: 'कृपया एक बैंक खाता चुनें',
  account_number_must_be_x: 'खाता संख्या 6-10 अंकों की लंबाई होनी चाहिए',
  minimum_length_is_x: 'न्यूनतम लंबाई {{x}} है',
  maximum_length_is_x: 'अधिकतम लंबाई {{x}} है',
  withdraw_information: 'निकासी सूचना',
  account_holder_name: 'खाता धारक का नाम',
  name_of_the_account_holder: 'खाता धारक का नाम',
  fill_in_bank_account: 'बैंक खाता भरें',
  please_upload_receipt: 'कृपया रसीद अपलोड करें',
  deposit_bonus: 'जमा बोनस',
  bank_transfer: 'बैंक हस्तांतरण',
  upload_receipt: 'रसीद अपलोड करें',
  select_bonus: 'बोनस चुनें',
  no_promotion: 'कोई प्रमोशन नहीं',

  // transaction history
  transaction_history: 'लेन-देन का इतिहास',

  // bet history
  bet_history: 'बेट इतिहास',
  game_provider: 'गेम प्रदाता',
  bet_count: 'बेट गणना',
  bet_amount: 'बेट राशि',
  win_loss: 'जीत/हानि',
  rebate: 'रिबेट',
  rebates: 'रिबेट्स',
  turnover: 'टर्नओवर',
  game_category: 'गेम श्रेणी',

  // all
  all: 'सभी',
  x_is_required: '{{x}} आवश्यक है',
  note: 'नोट',
  save: 'सहेजें',
  please_select_x: '{{x}} का चयन करें',
  title: 'शीर्षक',
  date: 'तारीख',
  type: 'प्रकार',
  status: 'स्थिति',
  remark: 'टिप्पणी',
  clear: 'साफ',
  date_from: 'तिथि से',
  date_to: 'तिथि तक',
  category: 'श्रेणी',
  search: 'खोजें',
  new: 'नया',
  change_password: 'पासवर्ड बदलें',
  logout: 'लॉगआउट',
  username: 'उपयोगकर्ता नाम',
  password: 'पासवर्ड',
  forgot_password: 'पासवर्ड भूल गए?',
  confirm: 'पुष्टि करें',
  dont_have_account: 'क्या आपका प्रयोक्ता खाता नहीं है?',
  contact_us: 'हमसे संपर्क करें',
  game_menu: 'गेम मेनू',
  bonus: 'बोनस',
  no_data: 'कोई डेटा नहीं',
  terms_and_condition: 'नियम और शर्तें',
  game_list: 'गेम सूची',
  play: 'खेलें',
  games: 'खेल',
  casino: 'कैसीनो',
  slots: 'स्लॉट्स',
  card: 'कार्ड',
  fishing: 'मछली पकड़ना',
  sport: 'खेल',
  sports: 'खेल',
  bonuses: 'बोनस',
  account_profile: 'खाता प्रोफ़ाइल',
  email: 'ईमेल',
  full_name: 'पूरा नाम',
  phone_number: 'फोन नंबर',
  level: 'स्तर',
  bank_list: 'बैंक सूची',
  in_maintenance: 'रखरखाव में',
  birthday: 'जन्मदिन',
  birthday_bonus: 'बोनस प्राप्त करने के लिए अपना जन्मदिन दर्ज करें! 🎉🎁',
  referral_code: 'रेफरल कोड',
  invalid_x: 'अमान्य {{x}}',
  extra: 'अतिरिक्त',
  referral: 'रेफरल',
  my_referral: 'मेरा रेफरल',
  my_claim: 'मेरा दावा',
  claim_profit: 'लाभ का दावा',
  available_profit: 'उपलब्ध लाभ',
  claimed_profit: 'दावा किया गया लाभ',
  all_time_profit: 'सभी समय का लाभ',
  referral_note_on_x: 'हर बार जब आपके दोस्त {{x}} पर खेलते हैं, तो लाभ कमाएं',
  tier: 'स्तर',
  commission: 'कमीशन',
  referred_member: 'संदर्भित सदस्य',
  profit_earned: 'कमाया गया लाभ',
  account_information: 'खाता जानकारी',
  invite_friends: 'मित्रों को आमंत्रित करें',
  how_it_work: 'यह कैसे काम करता है?',
  invite_note: 'अपने रेफरल कोड का उपयोग करके जब आपके दोस्त नए सदस्य बनते हैं तो विशेष रेफरल बोनस प्राप्त करें!',
  share_via: 'इसे शेयर करें',
  referral_link: 'रेफरल लिंक',
  total_profit: 'कुल लाभ',
  copied: 'कॉपी किया गया',
  nothing_to_claim: 'कुछ भी दावा करने के लिए नहीं',
  claim_history: 'दावा इतिहास',
  from: 'से',
  to: 'तक',
  choose_date: 'तारीख चुनें',
  select_both_date: 'कृपया दोनों तारीख चयन करें',
  hello: 'नमस्ते',
  my_downline: 'मेरा निचला पंक्ति',
  downline: 'निचला पंक्ति',
  forgot_password_contact: 'कृपया नीचे दिए गए ग्राहक सहायता से संपर्क करें ताकि आपके पासवर्ड बदलने में मदद कर सके',
  submit: 'प्रस्तुत',
  current_password: 'वर्तमान पासवर्ड',
  new_password: 'नया पासवर्ड',
  confirm_new_password: 'नया पासवर्ड की पुष्टि करें',
  already_have_an_account: 'क्या आपके पास पहले से ही खाता है?',
  share: 'शेयर',
  more_info: 'अधिक जानकारी',
  copy_link: 'लिंक कॉपी करें',
  share_to_friend: 'मित्र के साथ साझा करें',
  get_bonus: 'बोनस प्राप्त करें',
  copy_your_referral: 'अपना संदर्भ लिंक कॉपी करें और सोशल मीडिया पर भेजें और अपने निचले सदस्य से क्रेडिट कमाएं!',
  last_x_digit_bank_ref_number: 'बैंक रेफरेंस संख्या का अंतिम {{x}} अंक',
  bank_ref_number_validation_x: 'बैंक रेफरेंस संख्या आवश्यक है और {{x}} अक्षर लंबाई होनी चाहिए',
  card_game: 'कार्ड गेम',
  e_sports: 'ई-स्पोर्ट्स',
  instant_game: 'तत्काल खेल',
  sort: 'क्रमबद्ध',
  name: 'नाम',
  default: 'डिफ़ॉल्ट',
  e_sports_bonus: 'ई-स्पोर्ट्स बोनस',
  fishing_bonus: 'मछली पकड़ने का बोनस',
  casino_bonus: 'कैसीनो बोनस',
  sports_bonus: 'खेल बोनस',
  slots_bonus: 'स्लॉट्स बोनस',
  instant_game_bonus: 'तत्काल खेल बोनस',
  card_game_bonus: 'कार्ड गेम बोनस',
  all_bonus: 'सभी बोनस',
  how_to_get_referral_bonus: 'रेफरल बोनस कैसे प्राप्त करें?',
  steps_to_claim: 'बोनस को क्लेम करने के चरण',
  share_your_referral_code:
    'वाइबर, लाइन, टेलीग्राम, व्हाट्सएप, फेसबुक या मैसेंजर के माध्यम से खाता पंजीकृत करने के लिए अपना रेफरल कोड अपने दोस्तों के साथ साझा करें।',
  after_friend_register:
    '2. जब दोस्त New365 के साथ पंजीकृत होता है, तो रेफरल और रेफरल सदस्य को इस बोनस को क्लेम करने के लिए जमा करना चाहिए।',
  refer_a_friend_bonus: '3. दोस्त का संदर्भ बोनस गणना:',
  turnover_played: 'खेला गया टर्नओवर x कमीशन % = रेफरल फ्रेंड बोनस',
  example: 'उदाहरण:',
  turnover_formula_x: 'टर्नओवर (1,000,000) x 0.001% (कमीशन %) = 1,000{{x}}',
  note_bonus_only: '*नोट: बोनस केवल 12:00 बजे के बाद के अगले दिन दावा किया जा सकता है',
  note_bonus_only_x: '*नोट: बोनस केवल {{x}} के बाद के अगले दिन दावा किया जा सकता है',
  access_dashboard: '4. अधिकृत लाभ, कमीशन टियर, दावा इतिहास, और निचली पंक्ति की जांच के लिए डैशबोर्ड तक पहुँचें।',
  click_this_button_to_claim: 'रेफरल फ्रेंड बोनस का दावा करने के लिए इस बटन पर क्लिक करें',
  commision_tier: 'कमीशन टियर',
  member_downline: 'सदस्य निचली पंक्ति',
  bonus_tutorial_video: 'बोनस ट्यूटोरियल वीडियो',
  popularity: 'लोकप्रियता',
  no_balance: 'आपके पास आगे बढ़ने के लिए पर्याप्त शेष नहीं है',
  invalid_captcha: 'अमान्य कैप्चा',
  captcha: 'कैप्चा',
  claim_bonus: 'बोनस का दावा करें',
  claimed: 'दावा किया गया',
  bonus_claimed: 'बोनस दावा किया गया',
  online_banking: 'ऑनलाइन बैंकिंग',
  please_enter_email: 'कृपया पासवर्ड रीसेट प्रक्रिया के साथ आगे बढ़ने के लिए अपना ईमेल पता दर्ज करें',
  please_select_a_bank: 'कृपया एक बैंक का चयन करें',
  deposit_from_bank: 'बैंक से जमा करें',
  from_selected_promotion: 'चयनित प्रमोशन से',
  from_selected_bank: 'चयनित बैंक से',
  press_get_code: 'एसएमएस के माध्यम से सत्यापन कोड प्राप्त करने के लिए GET CODE बटन दबाएं',
  otp_sent: 'सत्यापन कोड भेजा गया है! जारी रखने के लिए नीचे सत्यापन कोड दर्ज करें',
  get_code: 'कोड प्राप्त करें',
  verify: 'सत्यापित करें',
  verification_code: 'सत्यापन कोड',
  otp_not_found: 'ओटीपी फ़ंक्शन नहीं मिला!',
  network_error: 'नेटवर्क त्रुटि',
  we_have_sent_otp: 'हमने आपके फोन नंबर पर एक OTP भेज दिया है',
  your_otp_expire: 'आपका OTP समाप्त हो जाएगा',
  didnt_receive: 'संदेश प्राप्त नहीं हुआ?',
  send_again: 'फिर से भेजें',
  resend_again: 'पुनः भेजें',
  invalid_verification: 'अमान्य सत्यापन कोड!',
  otp_expired: 'OTP समाप्त हो गया है, कृपया फिर से अपना फोन नंबर दर्ज करें और पुनः प्रयास करें!',
  otp_expired_redirect: 'OTP समाप्त हो गया है! लॉगिन पर पुनः निर्देशित किया जा रहा है',
  captcha_check_failed: 'कैप्चा जाँच विफल हुई!',
  verify_phonenumber: 'फोन नंबर सत्यापित करें',
  send_otp: 'OTP भेजें',
  success_verification: 'आपका फोन नंबर सत्यापित हो गया है! कृपया नीचे दिए गए फार्म को भरें और अपना पंजीकरण पूरा करें।',
  sign_up: 'साइन अप',
  refer_and_reward: 'रेफर एंड रिवॉर्ड',
  minor_note: 'इस साइट को देखने के लिए, सुनिश्चित करें कि आप 18 वर्ष से अधिक हैं और सहमत हैं',
  terms_of_service: 'सेवा की शर्तें',
  confirm_18: 'मैं पुष्टि करता हूँ कि मैं 18 वर्ष का हूं और मैंने पढ़ा है कि',
  this_field: 'यह क्षेत्र',
  slots_description: "स्लॉट मशीनों में आमतौर पर तीन या उससे अधिक 'रील' होती हैं, प्रत्येक में एक संख्या होती है...",
  play_now: 'अभी खेलें',
  live_casino: 'लाइव कैसीनो',
  promotion: 'प्रमोशन',
  profile: 'प्रोफ़ाइल',
  wallet: 'वॉलेट',
  reset_password: 'पासवर्ड रीसेट',
  promotions: 'प्रमोशन्स',
  cock_fighting: `कॉक लड़ाई`,
  home: `होम`,
  manage_your_bank_accounts: `अपने बैंक खातों का प्रबंधन करें`,
  history_of_deposit_withdraw_transactions: `जमा/निकास लेन-देन लेखों का इतिहास`,
  transaction_type: 'लेन-देन के प्रकार',
  period_history: 'अवधि का इतिहास',
  reset: 'रीसेट',
  learn_more: `और अधिक जानें`,
  number_exists: 'यह फोन नंबर पहले से ही पंजीकृत है!',
  number_not_exists: 'यह फोन नंबर पंजीकृत नहीं है!',
  ifsc_code: `आईएफएससी कोड`,
  fil_in_ifsc_code: `आईएफएससी कोड भरें`,
  optional: `वैकल्पिक`,
  app_download: `ऐप डाउनलोड करें`,
  payment_draft:
    'यदि आप पुष्टि करते हैं कि आपने पहले ही भुगतान कर दिया है, तो कृपया कुछ देर प्रतीक्षा करें क्योंकि भुगतान अभी भी संसाधित हो सकता है',
  be_a_vip: `VIP बनें`,
  experience_exlusivity: `अलौकिक अनुभव प्राप्त करें विशेष प्रचार, उपहार और छूट - आपकी अपेक्षा से भी अधिक!`,
  become_vip1: `मलेशिया की सबसे नवीन और सर्वसमावेशक ऑनलाइन गेमिंग वेबसाइट के रूप में, NEW365 कभी भी सदस्यों को सबसे अच्छा देने की कोशिश करना बंद नहीं करता। खेल विकल्पों से लेकर सेवा मानकों तक, NEW365 नवीनतम और सर्वोत्तम प्रदान करता है।`,
  become_vip2: `अनुभव को एक नए स्तर पर ले जाने के लिए, NEW365 एक विशेष VIP प्रोग्राम पेश कर रहा है जो वफादार सदस्यों को हमारे मनोरंजन प्रदाता के रूप में विश्वास बनाए रखने के लिए और भी अधिक कारण देगा। NEW365 VIP प्रोग्राम की पांच प्रमुख श्रेणियाँ हैं: कांस्य, चांदी, सोना, प्लेटिनम और हीरा। एक NEW365 VIP सदस्य के रूप में, आपको विशेष ऑफ़र, उपहार और बोनस के साथ-साथ प्राथमिकता सेवा प्राप्त होगी - आपके अद्वितीय गेमिंग अनुभव के लिए आवश्यक सब कुछ। विशेष छूट और श्रेणी उन्नयन बोनस आपकी गेमिंग लक्ष्यों को प्राप्त करने में आपकी मदद कर सकते हैं!`,
  become_vip3: `एक स्तर आधारित कार्यक्रम का उद्देश्य सदस्यों को क्रमिक रूप से उन्नत करने के लिए प्रेरणा प्रदान करना है। हालांकि हम सदस्यों को VIP स्तर की आवश्यकताओं को पूरा करने और ऊपर की ओर बढ़ने के लिए प्रोत्साहित करते हैं, हमारा ध्यान हमेशा सदस्यों की संतोषजनकता पर होता है, विशेष रूप से VIP सदस्यों पर। क्योंकि आपकी संतोषजनकता हमारी प्राथमिक चिंता है।`,
  to_all_member: `पहली बार अपग्रेड करने वाले सभी सदस्यों के लिए`,
  vip_journey1: `1. नियमित सदस्य निर्धारित समय सीमा के भीतर जमा की आवश्यकता पूरी करने के बाद स्वचालित रूप से VIP सदस्य में अपग्रेड हो जाएंगे।`,
  vip_journey2: `2. प्रणाली को आपके डेटा की सत्यापन और अद्यतन करने के लिए 2 कार्य दिवसों का समय दें।`,
  vip_journey3: `3. आराम करें, रिलैक्स करें और अपने VIP पुरस्कारों का आनंद लेना शुरू करें!`,
  benefits_as_our_vip: `हमारे VIP के रूप में लाभ`,
  vip_upgrading1: `आपकी सुरक्षा और सुरक्षा के लिए, आपको NEW365 VIP अपग्रेडिंग नीति में उल्लिखित सभी आवश्यक विवरण प्रदान करने की आवश्यकता है। हमारे टीम को प्रदान की गई जानकारी की समीक्षा और सत्यापन में अधिकतम 2 कार्य दिवस लग सकते हैं। इसके बाद, हम आपके नवीनतम स्थिति और जानकारी को हमारे सिस्टम में अपडेट करेंगे, और उसके बाद, आपकी VIP सदस्यता की यात्रा शुरू होगी!`,
  vip_upgrading2: `यह सुनिश्चित करने के लिए कि परिवर्तन सुचारू और कुशल तरीके से हो, कृपया सभी अपग्रेडिंग नियमों का पालन करें। यदि आपको सहायता की आवश्यकता हो तो कृपया हमारे ग्राहक सेवा प्रतिनिधियों से संपर्क करने में संकोच न करें।`,
  no_data_available: `कोई डेटा उपलब्ध नहीं`,
  how_does_it_work: `यह कैसे काम करता है?`,
  new_vip_journey: `NEW365 VIP यात्रा`,
  vip_benefits: `VIP लाभ`,
  faq: `प्रश्न`,
  membership_renewal: `सदस्यता नवीनीकरण`,
  about: `बारे में`,
  upgrade: `अपग्रेड`,
  benefits: `लाभ`,
  special_rebate: `विशेष छूट`,
  join_now: 'अब शामिल हों',
  upi_id: `UPI ID`,
  enter_upi_id: `UPI ID दर्ज करें`,
  please_download_x_to_play: 'खेलने के लिए कृपया {{x}} डाउनलोड करें',
  copy: 'कॉपी करें',
  download: 'डाउनलोड करें',
  please_enter_fullname_as_bank:
    'कृपया अपना पूरा नाम दर्ज करें। यह आपके बैंक खाताधारक के नाम के समान होना चाहिए अन्यथा जमा राशि अस्वीकार कर दी जाएगी।',
  update_kyc: 'केवाईसी अपडेट करें',
  kyc_verification: 'केवाईसी सत्यापन',
  ic_number: 'आईसी नंबर',
  nid_number: 'एनआईडी नंबर',
  ic_front: 'आईसी फ्रंट',
  nid_front: 'एनआईडी फ्रंट',
  selfie_video_with_ic: 'आईसी के साथ सेल्फी वीडियो',
  selfie_video_with_nid: 'एनआईडी के साथ सेल्फी वीडियो',
  kyc_submitted: 'केवाईसी सबमिट किया गया',
  file_too_large: 'फाइल बहुत बड़ी है। अधिकतम आकार {{x}} एमबी की अनुमति है।',
  limit_your_vid_to_5: 'अपना वीडियो 5 सेकंड तक सीमित करें',
  camera_access_denied: 'कैमरा एक्सेस अस्वीकृत। कृपया ब्राउज़र सेटिंग्स में कैमरा अनुमतियां सक्षम करें।',
  camera_not_available: 'कोई कैमरा नहीं मिला। कृपया कैमरा कनेक्ट करें या अपने डिवाइस की सेटिंग्स जांचें।',
  selfie_photo_with_nid: 'एनआईडी के साथ सेल्फी फोटो',
  selfie_photo_with_ic: 'आईसी के साथ सेल्फी',
  enter_text_on_the_left: 'बाईं ओर का टेक्स्ट दर्ज करें',
  otp_sent_enter_code_password: 'ओटीपी भेजा गया! कृपया कोड और अपना नया पासवर्ड दर्ज करें।',
  popular: 'लोकप्रिय',
  verified_by_play_protect: `प्ले प्रोटेक्ट द्वारा सत्यापित`,
  reviews: `26K समीक्षाएँ`,
  reviews_value: `4M+`,
  rated_for: `18+ के लिए रेटेड`,
  install: `स्थापित करना`,
  add_to_wishlist: `इच्छा सूची में जोड़ें`,
  about_this_app: `इस ऐप के बारे में`,
  app_description_1: `new365online में आपका स्वागत है, भारत का सबसे बेहतरीन ऑनलाइन कैसिनो प्लेटफॉर्म! यहां हम आपको रोमांचक गेमिंग अनुभव प्रदान करते हैं, जिसमें बड़े इनाम जीतने के बेहतरीन मौके मिलते हैं। चाहे आप क्लासिक कैसिनो गेम्स के फैन हों या आधुनिक हाई-स्टेक्स गेम्स के, हमारे पास हर किसी के लिए कुछ न कुछ है। अब अपने मोबाइल डिवाइस से ही Jili, Slots, PG, Live Casino, Crazy Time, Roulette, Poker और अन्य कई गेम्स का रोमांचक अनुभव आसानी से प्राप्त करें।`,
  app_description_2: `new365online हमेशा आपकी सुरक्षा और गेमिंग अनुभव को प्राथमिकता देता है। हमारा प्लेटफॉर्म पूरी तरह से लाइसेंस प्राप्त और सुरक्षित है, जो हमेशा निष्पक्ष खेल और जिम्मेदार गेमिंग सुनिश्चित करता है। हम उन्नत तकनीक का उपयोग करते हैं ताकि आपकी व्यक्तिगत जानकारी सुरक्षित रहे और आपको तेज़ जमा, त्वरित निकासी और 24x7 ग्राहक सहायता के साथ उच्च गुणवत्ता वाला गेमिंग अनुभव प्रदान कर सकें।`,
  why_choose_brand: `new365online क्यों चुनें?`,
  reason_1: `शीर्ष गेम प्रदाताओं से एकत्रित विशाल गेम संग्रह`,
  reason_2: `उपयोगकर्ता-अनुकूल इंटरफेस और आसान नेविगेशन`,
  reason_3: `आपके लिए आकर्षक बोनस और प्रमोशन`,
  reason_4: `तेज़ और सुरक्षित लेन-देन के लिए सुरक्षित भुगतान सुविधा`,
  reason_5: `अपनी पसंद के अनुसार हिंदी और अंग्रेजी भाषा चुनने की सुविधा`,
  app_description_3: `आज ही new365online पर रजिस्टर करें और भारत में सबसे बेहतरीन ऑनलाइन कैसिनो अनुभव का आनंद लें! चाहे आप गेमिंग के रोमांच की तलाश कर रहे हों या बड़े इनामों की, new365online आपको अनलिमिटेड गेमिंग अनुभव और मनोरंजन का द्वार प्रदान करता है।`,
  download_message: `आज ही हमारा ऐप डाउनलोड करें!`,
  hot_games: 'हॉट गेम्स',
  aadhaar_card_number: 'आधार कार्ड नंबर',
  selfie_with_aadhaar_card: 'आधार कार्ड के साथ सेल्फी',
  aadhaar_card_front: 'आधार कार्ड का फ्रंट',
  please_scan_using_your_x_app: '{{x}} ऐप का उपयोग करके स्कैन करें',
  // TODO: Translate to OTHER LANG
};

export default hi;
