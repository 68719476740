import React, { useState, useEffect } from 'react';
import { Backdrop, Box, Button, CircularProgress, IconButton, Modal, Tab } from '@mui/material';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { AnimatePresence, m } from 'framer-motion';
import Iconify from '../../../components/Iconify';
import Deposit from './deposit/Deposit';
import Withdraw from './withdraw/Withdraw';
import useLocales from '../../../hooks/useLocales';
import { useDispatch, useSelector } from '../../../redux/store';
import { openTransactionsDialog } from '../../../redux/slices/transactionsDialog';
import CustomModal from '../../../components/CustomModal';
import { varBounce } from '../../../components/animate';

const DepositWithdrawDialog = () => {
  const dispatch = useDispatch();

  const { translate } = useLocales();

  const { isOpenTransactionsDialog, siteSettings } = useSelector((x) => ({ ...x.transactionsDialog, ...x.lookup }));

  const [activeTab, setActiveTab] = useState('1');

  useEffect(() => {
    setActiveTab(isOpenTransactionsDialog?.isDeposit ? '1' : '2');
  }, [openTransactionsDialog]);

  const handleChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  return (
    <CustomModal
      sx={{
        '.MuiPaper-root': {
          border: '2px solid rgb(100, 0, 0)',
          background: '#6f1f1f',
        },
      }}
      open={isOpenTransactionsDialog}
      onClose={() => dispatch(openTransactionsDialog({ open: false, isDeposit: true }))}
    >
      <Box sx={{ px: { xs: 2, sm: 2, md: 2, lg: 3, xl: 3 }, pb: 3, pt: 6, position: 'relative' }}>
        <TabContext value={activeTab}>
          <Box sx={{ mb: 2, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <TabList
              onChange={handleChange}
              aria-label="lab API tabs example"
              indicatorColor="transparent"
              TabIndicatorProps={{
                style: { backgroundColor: 'transparent' },
              }}
              sx={{ background: '#846d34', width: 'fit-content', minHeight: 'unset', borderRadius: '15px' }}
            >
              <Tab
                label={translate('deposit')}
                value="1"
                sx={{
                  background: activeTab === '1' ? '#f2c556' : '#846d34',
                  minHeight: 'unset',
                  marginRight: '10px !important',
                  p: '5px 10px',
                  fontSize: '14px',
                  borderRadius: '16px',
                  color: activeTab === '1' ? '#1b1b1b !important' : 'white',
                }}
              />
              <Tab
                label={translate('withdraw')}
                value="2"
                sx={{
                  background: activeTab === '2' ? '#f2c556' : '#846d34',
                  minHeight: 'unset',
                  p: '5px 10px',
                  fontSize: '14px',
                  borderRadius: '16px',
                  color: activeTab === '2' ? '#1b1b1b !important' : 'white',
                }}
              />
            </TabList>
            <AnimatePresence>
              {siteSettings?.config_deposit_withdraw_phonenumber ? (
                <m.div {...varBounce().in} transition={{ delay: 2 }}>
                  {/* <IconButton
                    onClick={() =>
                      window.open(
                        `https://api.whatsapp.com/send?phone=${siteSettings?.config_deposit_withdraw_phonenumber}`,
                        '_blank'
                      )
                    }
                  >
                    <Iconify icon="ic:baseline-whatsapp" sx={{ color: 'rgb(0, 166, 80)' }} />
                  </IconButton> */}
                  <Button
                    sx={{
                      backgroundColor: 'rgb(0, 166, 80)',
                      borderRadius: '15px',
                      '&:hover': { backgroundColor: 'rgb(0, 166, 80)' },
                    }}
                    onClick={() =>
                      window.open(
                        `https://api.whatsapp.com/send?phone=${siteSettings?.config_deposit_withdraw_phonenumber}`,
                        '_blank'
                      )
                    }
                  >
                    <Iconify icon="ion:send" sx={{ color: 'white', width: 15, height: 15, mr: 1 }} />
                    <Iconify icon="ic:baseline-whatsapp" sx={{ color: 'white', width: 18, height: 18 }} />
                  </Button>
                </m.div>
              ) : (
                ''
              )}
            </AnimatePresence>
          </Box>
          <TabPanel value="1">
            <Deposit />
          </TabPanel>
          <TabPanel value="2">
            <Withdraw />
          </TabPanel>
        </TabContext>
      </Box>
    </CustomModal>
    //   </Box>
    // </Modal>
  );
};

export default DepositWithdrawDialog;
