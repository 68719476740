import { useNavigate, useParams } from 'react-router';

const useRedirect = () => {
  const navigate = useNavigate();

  const params = useParams();

  const customNavigate = (url, _state = {}) => {
    if (url === 'back') {
      navigate(-1);
    } else if (url && params?.domain) {
      navigate(`/${params.domain}/${url?.replace('/', '')}`, _state);
    } else {
      navigate('404');
    }
  };

  return customNavigate;
};

export default useRedirect;
