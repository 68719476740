// ----------------------------------------------------------------------

// IF THIS TRANSLATION IS INCORRECT PLEASE IGNORE THIS AS THIS TRANSLATION IS FOR DEMO PURPOSES ONLY
// We are happy if you can help improve the translation by sending an email to support@minimals.cc.

// ----------------------------------------------------------------------

const th = {
  // login
  login: `เข้าสู่ระบบ`,

  // register
  register: `ลงทะเบียน`,

  // home
  game_partners: `พันธมิตรเกม`,
  responsible_gaming: `การเล่นเกมอย่างมีความรับผิดชอบ`,
  supported_bank: `ธนาคารที่รองรับ`,
  load_more: `โหลดเพิ่มเติม`,

  // game
  choose_your_favourite_x_games: `เลือกเกม {{x}} ที่คุณชชอบ`,
  choose_your_favourite_x: `เลือกเกม {{x}}`,
  launch: `เล่น`,

  // notification
  notifications: `การแจ้งเตือน`,
  you_have_x_unread_messages: `คุณมี{{x}}ข้อความที่ยังไม่ได้อ่าน`,

  // deposit/withdraw
  drop_or_select_file: `วางหรือเลือกไฟล์`,
  drop_files_here: `วางไฟล์ที่นี่`,
  bank: `ธนาคาร`,
  bank_name: `ชื่อธนาคาร`,
  account_name: `ชื่อบัญชี`,
  account_number: `หมายเลขบัญชี`,
  bank_account: `บัญชีธนาคาร`,
  something_went_wrong: `บางอย่างผิดพลาด. โปรดติดต่อฝ่ายสนับสนุน`,
  deposit_amount_between_x_and_y: `จำนวนเงินฝากจะต้องอยู่ระหว่าง {{x}} ถึง {{y}}`,
  minimum_amount_is_x_y: `จำนวนเงินขั้นต่ำคือ {{x}}{{y}}`,
  payment_method: `วิธีการชำระเงิน`,
  mode: `โหมด`,
  transfer_to: `โอนไปที่`,
  select: `เลือก`,
  account: `บัญชี`,
  amount: `จำนวน`,
  deposit: `เงินฝาก`,
  withdraw: `ถอน`,
  amount_no_exceed_balance: `จำนวนเงินที่ถอนไม่ควรเกินยอดคงเหลือที่มีอยู่`,
  minimum_withdraw_amount_is_x: `จำนวนเงินถอนขั้นต่ำคือ {{x}}`,
  available_balance: `ยอดเงินคงเหลือ`,
  amount_withdrawn: `จำนวนเงินที่ถอนออก`,
  no_bank_account: `คุณไม่มีบัญชีใด ๆ ที่จะถอนออก เพิ่มทันที`,
  add_account: `เพิ่มบัญชี`,
  account_name_must_be_4: `ชื่อบัญชีต้องมีความยาวมากกว่า 4 ตัวขึ้นไป`,
  please_select_bank: `กรุณาเลือกบัญชีธนาคาร`,
  account_number_must_be_x: `หมายเลขบัญชีต้องมีความยาว 10-14 ตัว`,
  minimum_length_is_x: `ความยาวขั้นต่ำคือ {{x}}`,
  maximum_length_is_x: `ความยาวสูงสุดคือ {{x}}`,
  withdraw_information: `ข้อมูลการถอน`,
  account_holder_name: `ชื่อเจ้าของบัญชี`,
  name_of_the_account_holder: `ชื่อเจ้าของบัญชี`,
  fill_in_bank_account: `กรอกบัญชีธนาคาร`,
  please_upload_receipt: `กรุณาอัพโหลดใบเสร็จรับเงิน`,
  deposit_bonus: `โบนัสเงินฝาก`,
  bank_transfer: `โอนเงินผ่านธนาคาร`,
  upload_receipt: `อัพโหลดใบเสร็จรับเงิน`,
  select_bonus: `เลือกโบนัส`,
  no_promotion: `ไม่มีโปรโมชั่น`,

  // transaction history
  transaction_history: `ประวัติการทำรายการ`,

  // bet history
  bet_history: `ประวัติการเดิมพัน`,
  game_provider: `ผู้ให้บริการเกม`,
  bet_count: `นับเดิมพัน`,
  bet_amount: `จำนวนเงินเดิมพัน`,
  win_loss: `ชนะ/แพ้`,
  rebate: `ส่วนลด`,
  rebates: `ส่วนลด`,
  turnover: `เทิร์นโอเวอร์`,
  game_category: `หมวดเกม`,

  // all
  all: `ทั้งหมด`,
  x_is_required: `{{x}} เป็นสิ่งจำเป็น`,
  note: `บันทึก`,
  save: `บันทึก`,
  please_select_x: `โปรดเลือก {{x}}`,
  title: `ชื่อ`,
  date: `วันที่`,
  type: `หมวด`,
  status: `สถานะ`,
  remark: `ข้อสังเกต`,
  clear: `ชัดเจน`,
  date_from: `วันที่จาก`,
  date_to: `ถึงวันที่`,
  category: `หมวดหมู่`,
  search: `ค้นหา`,
  new: `ใหม่`,
  change_password: `เปลี่ยนรหัสผ่าน`,
  logout: `ออกจากระบบ`,
  username: `ชื่อผู้ใช้`,
  password: `รหัสผ่าน`,
  forgot_password: `ลืมรหัสผ่าน`,
  confirm: `ยืนยัน`,
  dont_have_account: `အသုံးပြုသူအကောင့် မရှိဘူးလား။`,
  contact_us: `ကြှနျုပျတို့ကိုဆကျသှယျရနျ`,
  game_menu: `เมนูเกม`,
  bonus: 'โบนัส',
  no_data: 'ไม่มีข้อมูล',
  terms_and_condition: 'ข้อกำหนดและเงื่อนไข',
  game_list: 'รายการเกม',
  play: 'เล่น',
  games: 'เกม',
  casino: 'คาสิโน',
  slots: 'สล็อต',
  card: 'การ์ด',
  fishing: 'ตกปลา',
  sport: 'กีฬา',
  sports: 'กีฬา',
  bonuses: 'โบนัส',
  account_profile: `โปรไฟล์บัญชี`,
  email: `อีเมล`,
  full_name: `ชื่อเต็ม`,
  phone_number: `หมายเลขโทรศัพท์`,
  level: `ระดับ`,
  bank_list: `รายชื่อธนาคาร`,
  in_maintenance: `ในการบำรุงรักษา`,
  birthday: `วันเกิด`,
  birthday_bonus: `ป้อนวันเกิดของคุณเพื่อรับโบนัส! 🎉🎁`,
  referral_code: `รหัสอ้างอิง`,
  invalid_x: `ไม่ถูกต้อง {{x}}`,
  extra: `พิเศษ`,
  referral: `การอ้างอิง`,
  my_referral: `การอ้างอิงของฉัน`,
  my_claim: `การเรียกร้องของฉัน`,
  claim_profit: `เรียกร้องผลกำไร`,
  available_profit: `กำไรที่มีอยู่`,
  claimed_profit: `กำไรที่เรียกร้อง`,
  all_time_profit: `กำไรตลอดเวลา`,
  referral_note_on_x: `รับผลกำไรทุกครั้งที่เพื่อนของคุณเล่นบน {{x}}`,
  tier: `ชั้น`,
  commission: `คณะกรรมการ`,
  referred_member: `สมาชิกที่ได้รับการแนะนำ`,
  profit_earned: `กำไรที่ได้รับ`,
  account_information: `ข้อมูลเกี่ยวกับบัญชี`,
  invite_friends: `เชิญเพื่อน ๆ`,
  how_it_work: `มันทำงานอย่างไร?`,
  invite_note: `รับโบนัสการแนะนำพิเศษเมื่อเพื่อนของคุณสมัครใช้รหัสอ้างอิงของคุณ!`,
  share_via: `แบ่งปันผ่านทาง`,
  referral_link: `ลิงค์ผู้อ้างอิง`,
  total_profit: `กำไรรวม`,
  copied: `คัดลอกแล้ว`,
  nothing_to_claim: `ไม่มีอะไรจะเรียกร้อง`,
  claim_history: `ประวัติการเรียกร้อง`,
  choose_date: `เลือกวันที่`,
  select_both_date: `เลือกทั้งวันที่เริ่มต้นและถึง`,
  hello: 'สวัสดี',
  my_downline: `ดาวน์ไลน์ของฉัน`,
  downline: `ดาวน์ไลน์`,
  forgot_password_contact: 'โปรดติดต่อฝ่ายสนับสนุนลูกค้าที่ระบุด้านล่างเพื่อช่วยคุณเปลี่ยนรหัสผ่าน',
  submit: 'ส่ง',
  current_password: 'รหัสผ่านปัจจุบัน',
  new_password: 'รหัสผ่านใหม่',
  confirm_new_password: 'ยืนยันรหัสผ่านใหม่',
  already_have_an_account: 'มีบัญชีอยู่แล้วหรือยัง?',
  share: 'แชร์',
  more_info: 'ข้อมูลเพิ่มเติม',
  copy_link: 'คัดลอกลิงก์',
  share_to_friend: 'แชร์ไปยังเพื่อน',
  get_bonus: 'รับโบนัส',
  copy_your_referral: 'คัดลอกลิงก์อ้างอิงของคุณและส่งไปยังสื่อสังคมเพื่อรับเครดิตจากลูกนอกของคุณ!',
  last_x_digit_bank_ref_number: `{{x}} หลักสุดท้ายของหมายเลขอ้างอิงธนาคาร`,
  bank_ref_number_validation_x: `ต้องระบุหมายเลขอ้างอิงธนาคารและต้องมีความยาวอักขระ {{x}} ตัว`,
  card_game: 'เกมการ์ด',
  e_sports: 'อีสปอร์ต',
  instant_game: 'เกมทันที',
  sort: 'เรียงลำดับ',
  name: 'ชื่อ',
  default: 'ค่าเริ่มต้น',
  e_sports_bonus: 'อีสปอร์ต โบนัส',
  fishing_bonus: 'ตกปลา โบนัส',
  casino_bonus: 'คาสิโน โบนัส',
  sports_bonus: 'กีฬา โบนัส',
  slots_bonus: 'สล็อต โบนัส',
  instant_game_bonus: 'เกมทันที โบนัส',
  card_game_bonus: 'เกมการ์ด โบนัส',
  all_bonus: 'ทั้งหมด โบนัส',
  how_to_get_referral_bonus: 'วิธีการรับโบนัสเพื่อนแนะนำ?',
  steps_to_claim: 'ขั้นตอนการเรียกรับโบนัส',
  share_your_referral_code:
    '1. แชร์รหัสแนะนำของคุณให้กับเพื่อนๆ เพื่อลงทะเบียนบัญชีผ่านทาง viber, messenger, telegram, facebook, twitter, หรือ instagram',
  after_friend_register:
    '2. หลังจากรีเจิสเตอร์ลงทะเบียนกับ New365, ทั้งแนะนำและสมาชิกที่ถูกแนะนำต้องฝากเงินเพื่อเรียกรับโบนัสนี้',
  refer_a_friend_bonus: '3. การคำนวณโบนัสเพื่อนแนะนำ:',
  turnover_played: 'ยอดเทิร์นที่เล่น x คอมมิชชั่น % = โบนัสเพื่อนแนะนำ',
  example: 'ตัวอย่าง:',
  turnover_formula_x: 'ยอดเทิร์น (1,000,000) x 0.002% (คอมมิชชั่น %) = 2,000{{x}}',
  note_bonus_only: '*หมายเหตุ: โบนัสสามารถเรียกรับได้เพียงวันถัดไปหลังจากเที่ยงคืน',
  note_bonus_only_x: '*หมายเหตุ: โบนัสสามารถเรียกร้องได้เฉพาะวันถัดไปหลังจาก {{x}}',
  access_dashboard: '4. เข้าถึงแดชบอร์ดเพื่อตรวจสอบกำไรที่มีสิทธิ, ระดับคอมมิชชั่น, ประวัติการเรียกรับ, และลูกโทรศัพท์',
  click_this_button_to_claim: 'คลิกที่ปุ่มนี้เพื่อเรียกรับโบนัสเพื่อนแนะนำ',
  commision_tier: 'ระดับคอมมิชชั่น',
  member_downline: 'สมาชิกที่ลงทะเบียนผ่านแนะนำ',
  bonus_tutorial_video: 'วิดีโอสอนโบนัส',
  popularity: 'ความนิยม',
  no_balance: 'คุณไม่มียอดเงินเพียงพอในการดำเนินการนี้',
  invalid_captcha: 'แคปต์ชาไม่ถูกต้อง',
  captcha: 'แคปช่า',
  claim_bonus: 'รับโบนัส',
  claimed: 'อ้างสิทธิ์',
  bonus_claimed: 'ขอรับโบนัสแล้ว',
  online_banking: 'ธนาคารออนไลน์',
  please_enter_email: 'กรุณากรอกที่อยู่อีเมลของคุณเพื่อดำเนินการตามขั้นตอนการรีเซ็ตรหัสผ่าน',
  please_select_a_bank: 'กรุณาเลือกธนาคาร',
  deposit_from_bank: 'ฝากเงินจากธนาคาร',
  from_selected_promotion: 'จากโปรโมชั่นที่เลือก',
  from_selected_bank: 'จากธนาคารที่เลือก',
  network_error: 'ข้อผิดพลาดของเครือข่าย',
  we_have_sent_otp: 'เราได้ส่ง OTP ไปยังหมายเลขโทรศัพท์ของคุณแล้ว',
  your_otp_expire: 'OTP ของคุณจะหมดอายุใน',
  didnt_receive: 'ไม่ได้รับข้อความ?',
  send_again: 'ส่งอีกครั้ง',
  resend_again: 'ส่งอีกครั้งใน',
  invalid_verification: 'รหัสยืนยันไม่ถูกต้อง!',
  otp_expired: 'OTP หมดอายุแล้ว โปรดป้อนหมายเลขโทรศัพท์ของคุณใหม่และลองอีกครั้ง!',
  otp_expired_redirect: 'OTP หมดอายุแล้ว! กำลังเปลี่ยนเส้นทางไปที่หน้าเข้าสู่ระบบ',
  captcha_check_failed: 'การตรวจสอบ Captcha ล้มเหลว!',
  verify_phonenumber: 'ยืนยันหมายเลขโทรศัพท์',
  send_otp: 'ส่ง OTP',
  success_verification:
    'หมายเลขโทรศัพท์ของคุณได้รับการยืนยันแล้ว! โปรดกรอกแบบฟอร์มด้านล่างเพื่อสำเร็จการลงทะเบียนของคุณ',
  payment_draft: 'หากคุณยืนยันว่าคุณได้ชำระเงินแล้ว โปรดรอสักครู่ เนื่องจากการชำระเงินอาจยังอยู่ระหว่างดำเนินการ',
};

export default th;
