import { Suspense, lazy } from 'react';
import { Navigate, useRoutes, useLocation } from 'react-router-dom';
// layouts
import MainLayout from '../layouts/main';
import DashboardLayout from '../layouts/dashboard';
import LogoOnlyLayout from '../layouts/LogoOnlyLayout';
import BlankLayout from '../layouts/BlankLayout';
// guards
import GuestGuard from '../guards/GuestGuard';
import AuthGuard from '../guards/AuthGuard';
// import RoleBasedGuard from '../guards/RoleBasedGuard';
// config
import { PATH_AFTER_LOGIN } from '../config';
// components
import LoadingScreen from '../components/LoadingScreen';
import DomainWrapper from '../layouts/DomainWrapper';

// ----------------------------------------------------------------------

const Loadable = (Component) => (props) =>
  (
    <Suspense fallback={<LoadingScreen loadable={true} />}>
      <Component {...props} />
    </Suspense>
  );

export default function Router() {
  return useRoutes([
    {
      path: '/:domain',
      element: <DomainWrapper />,
      children: [
        {
          path: 'auth',
          element: (
            <GuestGuard>
              <DashboardLayout />
            </GuestGuard>
          ),
          children: [
            {
              path: 'login',
              element: <Login />,
            },
            {
              path: 'register',
              element: <Register />,
            },
            {
              path: 'forgot-password',
              element: <ForgotPassword />,
            },
            {
              path: 'reset-password',
              element: <ResetPassword />,
            },
          ],
        },
        {
          path: '',
          element: <DashboardLayout />,
          children: [
            { path: '', element: <Home />, index: true },
            { path: 'games/:categSlug', element: <ProvidersByCategory /> },
            {
              path: 'games/:categSlug/:companyId',
              element: <Games />,
            },
          ],
        },

        // New routes here
        {
          path: '',
          element: (
            <AuthGuard>
              <DashboardLayout />
            </AuthGuard>
          ),
          children: [
            { element: <Navigate to={PATH_AFTER_LOGIN} replace />, index: true },
            { path: 'history', element: <TransactionHistory /> },
            { path: 'bet-history', element: <BetHistory /> },
          ],
        },

        // User Routes
        {
          path: 'user',
          element: (
            <AuthGuard>
              <DashboardLayout />
            </AuthGuard>
          ),
          children: [
            { element: <Navigate to={PATH_AFTER_LOGIN} replace />, index: true },
            { path: 'account', element: <AccountProfile /> },
            { path: 'banks', element: <MemberBankList /> },
            { path: 'change-password', element: <ChangePassword /> },
            { path: 'kyc', element: <MemberKyc /> },
          ],
        },

        // Referral Route
        {
          path: 'referral',
          element: (
            <AuthGuard>
              <DashboardLayout />
            </AuthGuard>
          ),
          children: [
            { element: <Referral />, index: true },
            { path: ':tab', element: <Referral /> },
            { path: 'refer-a-friend', element: <ReferAFriend /> },
          ],
        },

        // Promotion Route
        {
          path: 'promotion',
          element: (
            // <AuthGuard>
            <DashboardLayout />
            // </AuthGuard>
          ),
          children: [{ element: <Promotions />, index: true }],
        },

        // Payment Redirect { path: 'payStatus/:paymentType/:paymentName', element: <Home />, index: true },
        {
          path: 'payStatus',
          element: <LogoOnlyLayout />,
          children: [
            {
              path: ':paymentMethod',
              element: <PaymentStatus />,
              index: true,
            },
          ],
        },

        {
          path: 'payment',
          element: <LogoOnlyLayout />,
          children: [
            {
              path: ':paymentGateway/:orderId/:product',
              element: <PaymentStatus />,
              // index: true,
            },
          ],
        },

        // CustomPage Route
        {
          path: 'page',
          element: (
            // <AuthGuard>
            <DashboardLayout />
            // </AuthGuard>
          ),
          children: [
            { element: <Navigate to={PATH_AFTER_LOGIN} replace />, index: true },
            { path: ':customPage', element: <CustomPage /> },
          ],
        },

        // VIP
        {
          path: '',
          element: <DashboardLayout />,
          children: [{ path: 'vip', element: <Vip />, index: true }],
        },
      ],
    },
    // Download Page
    {
      path: 'download',
      element: <DownloadPage />,
    },
    {
      path: '',
      element: <Navigate to={PATH_AFTER_LOGIN} replace />,
    },
    // Main Routes
    {
      path: '*',
      element: <LogoOnlyLayout />,
      children: [
        { path: 'coming-soon', element: <ComingSoon /> },
        { path: 'maintenance', element: <Maintenance /> },
        { path: 'apk/new365.apk', element: <TriggerDownload /> },
        { path: '500', element: <Page500 /> },
        { path: '404', element: <Page404 /> },
        { path: '403', element: <Page403 /> },
        { path: '*', element: <Navigate to="/404" replace /> },
      ],
    },
    // {
    //   path: '/',
    //   element: <MainLayout />,
    //   children: [
    //     { element: <HomePage />, index: true },
    //     { path: 'about-us', element: <About /> },
    //     { path: 'contact-us', element: <Contact /> },
    //     { path: 'faqs', element: <Faqs /> },
    //   ],
    // },

    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}

// AUTHENTICATION
const Login = Loadable(lazy(() => import('../pages/auth/Login')));
const Register = Loadable(lazy(() => import('../pages/auth/Register')));
const ForgotPassword = Loadable(lazy(() => import('../pages/auth/ForgotPassword')));
const ResetPassword = Loadable(lazy(() => import('../pages/auth/ResetPassword')));
const ChangePassword = Loadable(lazy(() => import('../pages/auth/ChangePassword')));

// Home
const Home = Loadable(lazy(() => import('../views/Home/Home')));
const ProvidersByCategory = Loadable(lazy(() => import('../views/Home/ProvidersByCategory')));

// Games
const Games = Loadable(lazy(() => import('../views/Games')));

// Transaction History
const TransactionHistory = Loadable(lazy(() => import('../views/Home/TransactionHistory')));

// Bet History
const BetHistory = Loadable(lazy(() => import('../views/Home/BetHistory/BetHistory')));

// USER
const AccountProfile = Loadable(lazy(() => import('../views/Home/user/AccountProfile')));
const MemberBankList = Loadable(lazy(() => import('../views/Home/user/MemberBankList')));
const MemberKyc = Loadable(lazy(() => import('../views/Home/user/MemberKyc')));

// PROMOTION
const Promotions = Loadable(lazy(() => import('../views/Home/Promotions')));

// REFERRAL
const Referral = Loadable(lazy(() => import('../views/referral/Referral')));
const ReferAFriend = Loadable(lazy(() => import('../views/referral/ReferAFriend')));

// PAYMENT STATS
const PaymentStatus = Loadable(lazy(() => import('../pages/PaymentStatus')));

// CustomPage
const CustomPage = Loadable(lazy(() => import('../views/CustomPage')));

// Vip
const Vip = Loadable(lazy(() => import('../views/vip/Vip')));

// ANDROID APP DOWNLOAD PAGE
const DownloadPage = Loadable(lazy(() => import('../views/DownloadPage')));

// TRIGGER APK DOWNLOAD
const TriggerDownload = Loadable(lazy(() => import('../views/Home/TriggerDownload')));

// MAIN
const About = Loadable(lazy(() => import('../pages/About')));
const Contact = Loadable(lazy(() => import('../pages/Contact')));
const Faqs = Loadable(lazy(() => import('../pages/Faqs')));
const ComingSoon = Loadable(lazy(() => import('../pages/ComingSoon')));
const Maintenance = Loadable(lazy(() => import('../pages/Maintenance')));
const Pricing = Loadable(lazy(() => import('../pages/Pricing')));
const Payment = Loadable(lazy(() => import('../pages/Payment')));
const Page500 = Loadable(lazy(() => import('../pages/Page500')));
const Page403 = Loadable(lazy(() => import('../pages/Page403')));
const Page404 = Loadable(lazy(() => import('../pages/Page404')));
