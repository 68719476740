import React, { useState } from 'react';
import { Box, Grid, Typography, styled } from '@mui/material';
import { AnimatePresence, m } from 'framer-motion';
import useResponsive from '../../../hooks/useResponsive';
import Image from '../../../components/Image';
import { varFade } from '../../../components/animate';
import useAuth from '../../../hooks/useAuth';
import { PATH_AUTH, PATH_DASHBOARD } from '../../../routes/paths';
import { S3_ASSETS_URL } from '../../../config';
import { appLocale } from '../../../utils/const';
import { useDispatch, useSelector } from '../../../redux/store';
import { openTransactionsDialog } from '../../../redux/slices/transactionsDialog';
import { openLogin } from '../../../redux/slices/authPages';
import useRedirect from '../../../hooks/useRedirect';
import useLocales from '../../../hooks/useLocales';
import AccountPopover from '../header/AccountPopover';

const BottomNavBox = styled(Box)(({ theme }) => ({
  width: '100%',
  height: '60px',
  background: 'linear-gradient(to bottom, black, red)',
  position: 'fixed',
  bottom: 0,
  left: 0,
  zIndex: 100,
  boxShadow: '0 1px 2px -2px rgba(0,0,0,.64),0 3px 6px 0 rgba(0,0,0,.48),0 5px 12px 4px rgba(0,0,0,.36)',
  display: 'flex',
  alignItems: 'end',
  paddingTop: '10px',
  paddingBottom: '5px',
  right: 0,
  borderTop: '1px solid red',
}));

const CustomGridItem = styled(Grid)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  cursor: 'pointer',
  flexDirection: 'column',
}));

const NavText = styled(Typography)(({ theme }) => ({
  fontSize: '11px',
  fontFamily: 'sans-serif',
  whiteSpace: 'nowrap',
  color: '#fff',
  fontWeight: 600,
  marginTop: 0.5,
}));

const DepositBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  background: 'linear-gradient(to bottom, rgb(255, 227, 1), rgb(255, 0, 0))',
  flexDirection: 'column',
  height: '70px',
  marginTop: '-3rem',
  width: '70px',
  borderRadius: '100%',
  position: 'relative',
  cursor: 'pointer',
  '&::after': {
    content: "''",
    height: '70px',
    width: '70px',
    borderRadius: '100%',
    border: '2px solid red',
    position: 'absolute',
    boxShadow: '0px 0px 8px 1px red',
  },
}));

const MobileNav = () => {
  const { translate } = useLocales();

  const { isAuthenticated } = useAuth();

  const dispatch = useDispatch();

  const isSm = useResponsive('down', 'md');

  const navigate = useRedirect();

  const openDeposit = () => {
    if (isAuthenticated) {
      dispatch(
        openTransactionsDialog({
          open: true,
          isDeposit: true,
        })
      );
    } else {
      navigate('/auth/login');
    }
  };

  const openWithdraw = () => {
    if (isAuthenticated) {
      dispatch(
        openTransactionsDialog({
          open: true,
          isDeposit: false,
        })
      );
    } else {
      navigate('/auth/login');
    }
  };

  const clickAccount = () => {
    if (isAuthenticated) {
      navigate('/user/account');
    } else {
      navigate('/auth/login');
    }
  };

  return (
    <>
      {/* <AnimatePresence> */}
      <Box
        // component={m.div}
        //  {...varFade().inUp}
        sx={{ position: 'fixed', bottom: 0, mx: 1, zIndex: 2 }}
      >
        <BottomNavBox>
          <Grid container>
            <CustomGridItem item xs={12 / 5} onClick={() => navigate('/games/popular')}>
              <Image
                src={require('../../../assets/home/bottom_nav/games.png')}
                sx={{ width: '20px', height: '20px' }}
              />
              <NavText>{translate('games')}</NavText>
            </CustomGridItem>

            <CustomGridItem onClick={() => navigate('/promotion')} item xs={12 / 5}>
              <Image
                src={require('../../../assets/home/bottom_nav/promotion.png')}
                sx={{ width: '20px', height: '20px' }}
              />
              <NavText>{translate('promotion')}</NavText>
            </CustomGridItem>

            <CustomGridItem item xs={12 / 5}>
              <DepositBox onClick={() => openDeposit()}>
                <Image
                  src={require('../../../assets/home/bottom_nav/wallet.png')}
                  alt="Wallet"
                  sx={{ height: '20px', width: '22px' }}
                />
                <NavText>{translate('wallet')}</NavText>
              </DepositBox>
            </CustomGridItem>

            <CustomGridItem item xs={12 / 5} onClick={() => navigate('/games/live-casino')}>
              <Image
                src={require('../../../assets/home/bottom_nav/casino.png')}
                sx={{ height: '20px', width: '20px' }}
              />
              <NavText>{translate('casino')}</NavText>
            </CustomGridItem>

            <AccountPopover />

            {/* <CustomGridItem item xs={12 / 5}>
              <Image
                src={require('../../../assets/home/bottom_nav/profile.png')}
                sx={{ height: '20px', width: '20px', display: 'flex', justifyContent: 'center', '& .wrapper': {} }}
              />
              <NavText>{translate('profile')}</NavText>
            </CustomGridItem> */}
          </Grid>
        </BottomNavBox>
      </Box>
      {/* </AnimatePresence> */}
    </>
  );
};

export default MobileNav;

export const MobileNavSpacer = () => {
  const isSm = useResponsive('down', 'md');

  if (!isSm) return <></>;

  return (
    <Grid container sx={{ display: 'flex', justifyContent: 'center', opacity: 0 }}>
      <Grid item xs={4}>
        <Image
          src={`${S3_ASSETS_URL}home/navigation/MobileNavi-1.png`}
          sx={{ cursor: 'pointer' }}
          // onClick={() => window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })}
        />
      </Grid>
    </Grid>
  );
};
