import sanitize from 'sanitize-html';

/* eslint-disable no-useless-escape */
export function toSnakeCase(str) {
  return str.replace(/\s+/g, '_').toLowerCase();
}

export function isSnakeCase(str) {
  return /^[a-z]+(_[a-z]+)*$/.test(str);
}

export function getInitials(name) {
  const words = name.split(' ');

  const initials = words.map((word) => word.charAt(0).toUpperCase());

  return initials.join('');
}

export function fToSnake(inputString) {
  if (!inputString) return '';
  try {
    // Convert the string to lowercase
    const lowercaseString = inputString.toLowerCase();

    // Replace spaces, symbols, and consecutive symbols with underscores
    const formattedString = lowercaseString.replace(/[\s,!@#$%^&*()\-_+=<>.?/|\\]+/g, '_');

    return formattedString;
  } catch (error) {
    return inputString;
  }
}

export const getFontSize = (text) => {
  const textLength = text.length;
  if (textLength <= 10) {
    return '1rem'; // Set your desired font size for short text
  }
  if (textLength <= 20) {
    return '0.75rem'; // Set your desired font size for medium text
  }
  return '0.5rem'; // Set your desired font size for long text
};

export const getURLDisplay = () => {
  const currentURL = window.location.href;

  // Use a regular expression to extract the main domain
  const domain = currentURL.match(/^(?:https?:\/\/)?(?:[^@\n]+@)?(?:www\.)?([^:\/\n?]+)/im);

  // The main domain will be in the first capturing group of the regex match
  if (domain !== null && domain.length > 1) {
    return domain[1]?.toUpperCase();
  }

  return '';
};

export function isHTML(inputString) {
  // Regular expression to match HTML tags
  const htmlTagPattern = /<("[^"]*"|'[^']*'|[^'">])*>/;

  console.log(htmlTagPattern.test(inputString));

  // Check if the input string contains any HTML tags
  return htmlTagPattern.test(inputString);
}

const options = {
  allowedTags: false,
  allowedAttributes: false,
  parseStyleAttributes: false,
  allowedIframeHostnames: ['www.youtube.com'],
};

// eslint-disable-next-line no-return-assign
export const sanitizeHtml = (dirty) => ({
  __html: sanitize(dirty, options),
});

export const calculateFontSize = (text, baseFontSize = 12, maxLength = 20) => {
  const lengthFactor = Math.min(1, maxLength / text.length);
  const fontSize = baseFontSize * lengthFactor;
  return `${fontSize}px`;
};

// Function to replace URLs with clickable links
export const renderWithLinks = (text) => {
  const urlRegex = /(https?:\/\/[^\s]+)/g;
  return text.split(urlRegex).map((part, index) => {
    if (part.match(urlRegex)) {
      return (
        <a key={index} href={part} style={{ color: 'lightblue' }} target="_blank" rel="noopener noreferrer">
          {part}
        </a>
      );
    }
    return part;
  });
};

export const getCountryFromUrl = () => {
  const pathSegments = window.location.pathname.split('/');
  const countryIndex = 1; // Assuming the country code is at index 1

  const subDomains = process.env.REACT_APP_SUB_DOMAINS ? process.env.REACT_APP_SUB_DOMAINS.split(',') : [];

  const defaultCountry = subDomains?.length ? subDomains[0] : '';

  if (pathSegments.length > countryIndex && pathSegments?.[countryIndex]) {
    const country = pathSegments[countryIndex];

    if (subDomains.includes(country)) return country;
  }

  return defaultCountry || '';
};

export const getOtpErrorMessage = (translate, error) => {
  let errorMessage = error?.response?.data?.message || error?.response?.data || error.message;

  if (error?.code) {
    switch (error?.code) {
      case 'auth/invalid-verification-code':
        errorMessage = translate('invalid_verification');
        break;
      case 'auth/code-expired':
        errorMessage = translate('otp_expired');
        break;
      case 'auth/invalid-phone-number' || 'auth/too-many-requests':
        errorMessage = translate('invalid_x', { x: translate('phone_number') });
        break;
      case 'auth/captcha-check-failed':
        errorMessage = translate('captcha_check_failed');
        break;

      default:
        errorMessage = translate('network_error');
        break;
    }
  }

  return errorMessage;
};
