import React, { useEffect, useMemo, useState } from 'react';
import {
  Box,
  Button,
  Card,
  Dialog,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  InputBase,
  MenuItem,
  NativeSelect,
  Radio,
  RadioGroup,
  Select,
  Stack,
  Typography,
} from '@mui/material';
import { styled } from '@mui/system';
import { useSnackbar } from 'notistack';

import { AnimatePresence, m } from 'framer-motion';
import { useParams } from 'react-router';
import ReactPixel from 'react-facebook-pixel';

import { useSelector, useDispatch } from '../../../../redux/store';

import conObj from '../../../../utils/connection-assistant';
import useLocales from '../../../../hooks/useLocales';
import { UploadSingleFile } from '../../../../components/upload';
import { openTransactionsDialog } from '../../../../redux/slices/transactionsDialog';
import { fCurrency, fNumberCommaSeparated } from '../../../../utils/formatNumber';
import Iconify from '../../../../components/Iconify';
import CustomModal from '../../../../components/CustomModal';
import Image from '../../../../components/Image';
import { varBounce } from '../../../../components/animate';
import { isHTML, renderWithLinks } from '../../../../utils/formatString';
import { openConfirm } from '../../../../redux/slices/confirm';
import { closeLogin } from '../../../../redux/slices/authPages';
import PopupQrCode from './PopupQrCode';

// Drawer
// import ConfirmDepositDrawer from './ConfirmDepositDrawer';

const TypographyCustom = styled(Typography)({
  color: 'white',
  fontSize: '14px',
});

const Deposit = () => {
  const { translate } = useLocales();

  const dispatch = useDispatch();

  const { enqueueSnackbar } = useSnackbar();

  const { memberBalance, siteSettings } = useSelector((x) => x.lookup);
  const promotionID = useSelector((x) => x.transactionsDialog.isOpenTransactionsDialog.promotionID);

  const params = useParams();

  const [depositAmount, setDepositAmount] = useState(0);
  const [extraAmount, setExtraAmount] = useState(50);
  const [noPromotion, setNoPromotion] = useState(false);
  const [confirmDeposit, setConfirmDeposit] = useState(false);
  const [currencyTopup, setCurrencyTopup] = useState([]);
  const [overrideCurrencyTopup, setOverrideCurrencyTopup] = useState([]);
  const [currency, setCurrency] = useState('');
  const [bankTypeName, setBankTypeName] = useState('');
  const [minimumDeposit, setMinimumDeposit] = useState('');
  const [maximumDeposit, setMaximumDeposit] = useState('');
  const [paymentMethods, setPaymentMethods] = useState({});
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState('');
  const [bankList, setBankList] = useState();
  const [selectedBank, setSelectedBank] = useState('');
  const [errors, setErrors] = useState({});
  const [bankSlip, setBankSlip] = useState(null);
  const [promotions, setPromotions] = useState(null);
  const [selectedPromotion, setSelectedPromotion] = useState(0);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [referenceNumber, setReferenceNumber] = useState('');
  const [showBankQrCode, setShowBankQrCode] = useState(null);
  const [selectedBankSource, setSelectedBankSource] = useState('');
  const [bankSourceList, setBankSourceList] = useState({});
  const [isAutoUpdatePromotion, setIsAutoUpdatePromotion] = useState(false);
  const [maintenanceRemark, setMaintenanceRemark] = useState('');
  const [bankInactiveInstruction, setBankInactiveInstruction] = useState('');
  const [customWAButton, setCustomWAButton] = useState({ title: '', url: '' });
  const [iFrameLink, setIFramLink] = useState(null);
  const [topPayQrCode, setTopPayQrCode] = useState(null);
  // const [amountInThousandths, setAmountInThousandths] = useState(false);

  const [showAlert, setShowAlert] = useState({
    show: false,
    message: '',
  });

  const filteredPromotions = useMemo(() => {
    if (isAutoUpdatePromotion && promotions?.length && Number(depositAmount || 0) > 0) {
      const _promotions = promotions.filter(
        (p) =>
          Number(depositAmount) >= Number(p?.min_deposit || 0) &&
          (Number(p?.max_deposit) === 0 || Number(depositAmount) <= Number(p?.max_deposit))
      );

      return _promotions || [];
    }

    return promotions || [];
  }, [depositAmount, isAutoUpdatePromotion, promotions]);

  const isHideDeposit = useMemo(
    () => maintenanceRemark || bankInactiveInstruction,
    [maintenanceRemark, bankInactiveInstruction]
  );

  const selectedBankData = useMemo(() => {
    if (selectedBank && bankList?.length) {
      const _selectedBank = bankList.find((f) => f.BankAccount.id === selectedBank);

      if (_selectedBank) return _selectedBank;
    }

    return {};
  }, [selectedBank, bankList]);

  useEffect(() => {
    if (filteredPromotions?.length) {
      if (promotionID) {
        const initialPromotion = filteredPromotions.find((filteredPromotion) => filteredPromotion.id === promotionID);

        if (initialPromotion) {
          setSelectedPromotion(initialPromotion.id);
        } else {
          setSelectedPromotion(0);
        }
      } else {
        // setSelectedPromotion(filteredPromotions[0]?.id);
      }
    }
    //  else setSelectedPromotion(0);
  }, [filteredPromotions, promotionID]);

  useEffect(() => {
    getDepositMethods();
  }, []);

  // useEffect(() => {
  //   if (memberBalance?.currencyCode.toUpperCase() === 'MMK' || memberBalance?.currencyCode.toUpperCase() === 'VND') {
  //     setAmountInThousandths(true);
  //   }
  // }, [memberBalance]);

  useEffect(() => {
    if (selectedPaymentMethod && Object.keys(paymentMethods)?.length > 0) {
      setBankList(paymentMethods?.[selectedPaymentMethod]?.Bank);
      setSelectedBank(paymentMethods?.[selectedPaymentMethod]?.Bank[0]?.BankAccount?.id);
      const bankSourceCodes = paymentMethods?.[selectedPaymentMethod]?.Bank[0]?.deposit_source_bank_codes;
      if (bankSourceCodes && Object.keys(bankSourceCodes)?.length) {
        setBankSourceList(bankSourceCodes);
        setSelectedBankSource(Object.keys(bankSourceCodes)[0]);
      } else {
        setBankSourceList({});
        setSelectedBankSource('');
      }
    }
  }, [paymentMethods, selectedPaymentMethod]);

  useEffect(() => {
    if (bankList) {
      const _selectedBank = bankList.find((f) => f.BankAccount?.id === selectedBank);
      setMaintenanceRemark('');
      setBankInactiveInstruction('');
      setCustomWAButton({ title: '', url: '' });

      if (_selectedBank) {
        const {
          min_deposit: minDeposit,
          max_deposit: maxDeposit,
          is_maintenance: isMaintenance,
          maintenance_remark: _maintenanceRemark,
          show_instruction: showInstruction,
          active,
          custom_button_title: customButtonTitle,
          custom_button_url: customButtonUrl,
        } = _selectedBank?.BankAccount;

        /**
         * set bank/promotion minimum deposit as default
         * whichever is greater
         *  */
        const _selectedPromotion = promotions?.find((f) => f.id === selectedPromotion);

        const promoMaxDeposit = _selectedPromotion?.max_deposit || 0;

        const _maxDeposit = maxDeposit || 0;

        const _minDeposit = minDeposit || 0;

        const sanitizedPromoMaxDeposit = parseFloat(promoMaxDeposit.toString().replace(/[^0-9.]/g));
        const sanitizedBankMaxDeposit = parseFloat(_maxDeposit.toString().replace(/[^0-9.]/g));

        const maxDepositValue =
          sanitizedBankMaxDeposit <= sanitizedPromoMaxDeposit ? sanitizedBankMaxDeposit : sanitizedPromoMaxDeposit;

        setMinimumDeposit(
          // amountInThousandths
          //   ? parseFloat(minDeposit.replace(/[^0-9.]/g, '')) * 1000 :
          parseFloat(_minDeposit.toString().replace(/[^0-9.]/g, ''))
        );

        setMaximumDeposit(
          // amountInThousandths
          //   ? parseFloat(maxDeposit.replace(/[^0-9.]/g, '')) * 1000 :
          parseFloat(maxDepositValue)
        );

        const promoMinDeposit = _selectedPromotion?.min_deposit || 0;

        const sanitizedPromoMinDeposit = parseFloat(promoMinDeposit.toString().replace(/[^0-9.]/g));
        const sanitizedBankMinDeposit = parseFloat(_minDeposit.toString().replace(/[^0-9.]/g));

        const depositValue =
          sanitizedBankMinDeposit >= sanitizedPromoMinDeposit ? sanitizedBankMinDeposit : sanitizedPromoMinDeposit;

        if (_selectedBank?.OverrideCurrencyTopup && _selectedBank?.OverrideCurrencyTopup?.length) {
          // console.log(_selectedBank?.OverrideCurrencyTopup);
          setOverrideCurrencyTopup(_selectedBank?.OverrideCurrencyTopup);
        } else setOverrideCurrencyTopup([]);

        if (isMaintenance && Number(isMaintenance) === 1) setMaintenanceRemark(_maintenanceRemark || '');

        if (Number(active || 0) === 0) setBankInactiveInstruction(showInstruction || '');

        setCustomWAButton({ title: customButtonTitle || '', url: customButtonUrl || '' });

        if (!isAutoUpdatePromotion) setDepositAmount(depositValue);

        // setCurrencyTopup(Object.values(_selectedBank?.CurrencyTopup));
      } else {
        setMinimumDeposit('');
        setMaximumDeposit('');

        // setCurrencyTopup([]);
      }
    }
  }, [bankList, isAutoUpdatePromotion, promotions, selectedBank, selectedPromotion]);

  useEffect(() => {
    let valid = true;
    const _errors = {};
    setErrors({});

    const _selectedPromotion = promotions?.find((f) => f.id === selectedPromotion);
    // promo minimum deposit
    const minDeposit = _selectedPromotion?.min_deposit;

    if (selectedBank) {
      // if (!depositAmount) {
      //   valid = false;
      //   _errors.depositAmount = translate('x_is_required', { x: `${translate('deposit')} ${translate('amount')}` });
      // } else
      if (parseFloat(depositAmount.toString().replaceAll(',', '')) < parseFloat(minDeposit)) {
        valid = false;
        _errors.depositAmount = `${translate('minimum_amount_is_x_y', { x: currency, y: minDeposit })}. (${translate(
          'from_selected_promotion'
        )})`;
      } else if (
        parseFloat(depositAmount.toString().replaceAll(',', '')) < minimumDeposit ||
        parseFloat(depositAmount.toString().replaceAll(',', '')) > maximumDeposit
      ) {
        // if maximumDeposit === 0 means no min/max deposit
        if (maximumDeposit !== 0) {
          valid = false;
          _errors.depositAmount = translate('deposit_amount_between_x_and_y', { x: minimumDeposit, y: maximumDeposit });
        } else if (parseFloat(depositAmount.toString().replaceAll(',', '')) < minimumDeposit) {
          valid = false;
          _errors.depositAmount = translate('minimum_amount_is_x_y', { x: currency, y: minimumDeposit });
        }
      } else {
        _errors.depositAmount = '';
      }
    }

    setErrors(_errors);
  }, [depositAmount, selectedPromotion, minimumDeposit, maximumDeposit]);

  // get payment method(s)
  const getDepositMethods = () => {
    conObj
      .post('transactions/deposits.json')
      .then((res) => {
        if (res.status === 200) {
          if (res.data?.data?.status === 1) {
            // success
            const {
              payment_method: paymentMethod,
              promotions,
              CurrencyTopup,
              alert,
              auto_update_promotion_list: autoUpdatePromotionList,
            } = res.data?.data?.data;

            setIsAutoUpdatePromotion(autoUpdatePromotionList || false);

            if (alert) {
              // setShowAlert({ show: true, message: alert });
              openConfirm({
                title: translate('note'),
                subTitle: alert,
                onCancel: () => dispatch(openTransactionsDialog({ open: false })),
                cancelText: 'Cancel',
                confirmText: 'Ok',
                hasClose: false,
              });
            }

            // set currency topup
            setCurrencyTopup(Object.values(CurrencyTopup));

            if (Object.keys(paymentMethod).length > 0) {
              setPaymentMethods(paymentMethod);

              if (paymentMethod) setSelectedPaymentMethod(Object.keys(paymentMethod)[0]);
            }

            if (promotions?.length > 0) {
              setPromotions(promotions);
              if (!autoUpdatePromotionList) setSelectedPromotion(promotions[0].id);
              else setSelectedPromotion(0);
            }

            // currency
            setCurrency(memberBalance?.currencyCode);

            // // bank type name
            setBankTypeName(paymentMethod?.bank?.name);
          } else {
            // fail
          }
        }
      })
      .catch((err) => {
        console.log(`Server Error: ${err.message}`);
      });
  };

  const onSubmit = () => {
    if (isValid()) {
      setIsSubmitting(true);
      if (selectedPaymentMethod === 'bank') {
        topupByBankTransfer();
      } else {
        topupByOnlinePayment();
      }
    }
  };

  const topupByBankTransfer = () => {
    const requestData = {
      'transaction-amount': parseFloat(depositAmount.toString().replaceAll(',', '')),
      // 'transaction-amount': amountInThousandths ? depositAmount / 1000 : depositAmount,
      'transaction-file_base64_1': bankSlip?.file?.base64,
      'transaction-promotion_id': selectedPromotion,
      'transaction-bank_account_id': selectedBank,
      'transaction-bank_type': selectedPaymentMethod,
      /**
       * set 0 in env if don't want reference number
       */
      ...(process.env.REACT_APP_REF_NUMBER_LENGTH > 0 && { 'transaction-reference_no': referenceNumber.trim() }),
    };

    conObj
      .post('transactions/topup.json', requestData)
      .then((res) => {
        if (res.status === 200) {
          if (res.data?.data?.status === 1) {
            // success
            // message to show
            // res.data?.data?.msg;
            const snackbarOptions = { variant: 'success', autoHideDuration: 5000 };
            enqueueSnackbar(res.data?.data?.msg, snackbarOptions);
            setIsSubmitting(false);

            ReactPixel.track('DepositSubmitted');

            dispatch(openTransactionsDialog({ open: false, isDeposit: true }));
          }
        }
      })
      .catch((err) => {
        console.log(`Server Error: ${err.message}`);
        setIsSubmitting(false);
        enqueueSnackbar(err.message, { variant: 'error', autoHideDuration: 5000 });
      });
  };

  const topupByOnlinePayment = () => {
    if (!selectedPaymentMethod) {
      enqueueSnackbar(translate('please_select_x', { x: translate('payment_method') }), {
        variant: 'error',
        autoHideDuration: 5000,
      });
      setIsSubmitting(false);
      return;
    }

    const requestData = {
      'transaction-amount': parseFloat(depositAmount.toString().replaceAll(',', '')),
      'transaction-promotion_id': selectedPromotion,
      'transaction-bank_account_id': selectedBank,
      'transaction-redirect_url': `${window.location.origin}/${params?.domain}/payStatus/${selectedPaymentMethod}`,
      // 'transaction-redirect_url': 'https://www.google.com',
    };

    if (selectedBankSource) {
      requestData['transaction-deposit_source_bank_code'] = selectedBankSource;
    }

    conObj
      .post(`${selectedPaymentMethod}/topup_${selectedPaymentMethod}.json`, requestData)
      .then((res) => {
        if (res.status === 200) {
          if (res.data?.data?.status === 1) {
            ReactPixel.track('DepositSubmitted');
            // setIsSubmitting(false);
            if (res.data?.data?.qrCode) {
              setConfirmDeposit(false);
              setTopPayQrCode(res.data?.data?.qrCode);
              setIsSubmitting(false);
            } else if (res.data?.data?.iFrameLink) {
              setConfirmDeposit(false);
              setIFramLink(res.data?.data?.iFrameLink);
              setIsSubmitting(false);
            } else {
              window.location.replace(res.data?.data?.redirectUrl);
            }
          }
        }
      })
      .catch((err) => {
        setIsSubmitting(false);
        console.log(`Server Error: ${err.message}`);
        enqueueSnackbar(err.message, { variant: 'error', autoHideDuration: 5000 });
      });
  };

  const isValid = () => {
    let valid = true;
    const _errors = {};

    const _selectedPromotion = promotions?.find((f) => f.id === selectedPromotion);
    // promo minimum deposit
    const minDeposit = _selectedPromotion?.min_deposit;

    if (selectedBank) {
      // if (!depositAmount) {
      //   valid = false;
      //   _errors.depositAmount = translate('x_is_required', { x: `${translate('deposit')} ${translate('amount')}` });
      // } else
      if (parseFloat(depositAmount.toString().replaceAll(',', '')) < parseFloat(minDeposit)) {
        valid = false;
        _errors.depositAmount = `${translate('minimum_amount_is_x_y', { x: currency, y: minDeposit })}. (${translate(
          'from_selected_promotion'
        )})`;
      } else if (
        parseFloat(depositAmount.toString().replaceAll(',', '')) < minimumDeposit ||
        parseFloat(depositAmount.toString().replaceAll(',', '')) > maximumDeposit
      ) {
        // if maximumDeposit === 0 means no min/max deposit
        if (maximumDeposit !== 0) {
          valid = false;
          _errors.depositAmount = translate('deposit_amount_between_x_and_y', { x: minimumDeposit, y: maximumDeposit });
        } else if (parseFloat(depositAmount.toString().replaceAll(',', '')) < minimumDeposit) {
          valid = false;
          _errors.depositAmount = translate('minimum_amount_is_x_y', { x: currency, y: minimumDeposit });
        }
      } else {
        _errors.depositAmount = '';
      }
    }

    // Bank specific validation
    if (selectedPaymentMethod === 'bank') {
      if (!selectedBank) {
        valid = false;
        _errors.selectedBank = translate('please_select_x', { x: translate('bank_account') });
      } else {
        _errors.selectedBank = '';
      }

      if (!bankSlip?.file) {
        valid = false;
        _errors.bankSlip = translate('please_upload_receipt');
      } else {
        _errors.bankSlip = '';
      }

      if (process.env.REACT_APP_REF_NUMBER_LENGTH > 0) {
        if (referenceNumber.trim().length < process.env.REACT_APP_REF_NUMBER_LENGTH) {
          valid = false;
          _errors.referenceNumber = translate('bank_ref_number_validation_x', {
            x: process.env.REACT_APP_REF_NUMBER_LENGTH,
          });
        } else {
          _errors.referenceNumber = '';
        }
      }
    } else if (selectedPaymentMethod !== 'bank') {
      if (!selectedBank && !bankList) {
        valid = false;
        setShowAlert({ show: true, message: translate('please_select_x', { x: translate('payment_method') }) });
      } else if (!selectedBank) {
        valid = false;
        _errors.selectedBank = translate('please_select_x', { x: translate('payment_method') });
      } else {
        _errors.selectedBank = '';
      }
    }

    setErrors(_errors);
    return valid;
  };

  const toggleDrawer = (open) => {
    // alert("hello");
    // if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
    //   return;
    // }

    // alert('hello');
    setConfirmDeposit(open);
  };

  const handleFileDrop = (acceptedFiles, mediaType) => {
    const file = acceptedFiles[0];

    if (file) {
      const url = {
        preview: URL.createObjectURL(file),
      };
      getBase64(file)
        .then((result) => {
          file.base64 = result;
          console.log('File Is', file);
          setBankSlip({ file, filePreview: url.preview });
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const getBase64 = (file) => {
    return new Promise((resolve) => {
      let fileInfo;
      let baseURL = '';
      // Make new FileReader
      const reader = new FileReader();

      // Convert the file to base64 text
      reader.readAsDataURL(file);

      // on reader load somthing...
      reader.onload = () => {
        // Make a fileInfo Object
        console.log('Called', reader);
        baseURL = reader.result;
        console.log(baseURL);
        resolve(baseURL);
      };

      console.log(fileInfo);
    });
  };

  const makeCurrencyTopup = (amount) => {
    const _amount = amount;
    // const _amount = amountInThousandths ? amount * 1000 : amount;
    return (
      <Grid item xl={4} lg={4} md={4} sm={4} xs={4}>
        <Box
          onClick={() => setDepositAmount(_amount)}
          sx={{
            backgroundColor: 'rgb(193, 14, 6)',
            p: '16px 4px 11px',
            borderRadius: '6px',
            cursor: 'pointer',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            position: 'relative',
            borderColor: depositAmount === _amount ? '#2283f6' : 'transparent',
            borderWidth: '1px',
            borderStyle: 'solid',
            height: '100%',
          }}
        >
          {selectedPromotion.toString() !== '0' && (
            <span
              style={{
                background: '#271111',
                borderRadius: '2px',
                fontSize: '10px',
                fontWeight: 700,
                height: '16px',
                padding: '0 4px',
                right: '4px',
                top: '-2px',
                transform: 'scale(.8)',
                position: 'absolute',
                zIndex: 10,
              }}
            >
              <span>+</span>
              {/* <span>{currency}</span> */}
              <span>{getPromotion(_amount)}</span>
            </span>
          )}
          <TypographyCustom sx={{ fontWeight: 700, wordBreak: 'break-all' }}>{`${fCurrency(
            _amount
          )}`}</TypographyCustom>
          {depositAmount === _amount && (
            <svg
              width="17"
              height="16"
              viewBox="0 0 17 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              style={{
                position: 'absolute',
                bottom: 0,
                color: 'yellow',
                height: '16px',
                right: '-1px',
                width: '17px',
                zIndex: 1,
              }}
            >
              <path d="M17 0L0 16H12C14.7614 16 17 13.7614 17 11V0Z" fill="currentColor" />
              <path
                d="M9.35493 11.6942L11.1083 12.8822L13.6758 9.00007"
                stroke="#271111"
                strokeWidth="0.822792"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          )}
        </Box>
      </Grid>
    );
  };

  const getPromotion = (amount) => {
    const amountFloat = parseFloat(amount.toString().replaceAll(',', ''));
    const _selectedPromotion = promotions?.find((f) => f.id === selectedPromotion);

    let bonus = 0;
    if (_selectedPromotion.bonus_type === 'percent') {
      bonus = (_selectedPromotion.bonus / 100) * amountFloat;
    } else {
      bonus = _selectedPromotion.bonus;
    }

    const maxBonusAmount = _selectedPromotion?.max_bonus_amount;

    /**
     * if max_bonus_amount === 0 - no cap
     * else, set cap
     */
    if (parseFloat(maxBonusAmount) > 0 && bonus > parseFloat(maxBonusAmount)) {
      bonus = _selectedPromotion?.max_bonus_amount;
    }

    const minDeposit = _selectedPromotion?.min_deposit;

    /**
     * bonus only apply if deposit amount => minDeposit
     */
    if (amountFloat < parseFloat(minDeposit)) {
      bonus = 0;
    }

    return fCurrency(bonus);
  };

  const getDisplayDepositBonus = () => {
    const _selectedPromotion = promotions?.find((f) => f.id === selectedPromotion);

    const depositAmountFloat = parseFloat(depositAmount.toString().replaceAll(',', ''));

    const bonusType =
      _selectedPromotion.bonus_type === 'percent'
        ? `${_selectedPromotion.bonus}%`
        : `${currency}${_selectedPromotion.bonus}`;

    let bonus = 0;

    if (_selectedPromotion.bonus_type === 'percent') {
      bonus = (_selectedPromotion.bonus / 100) * depositAmountFloat;
    } else {
      bonus = _selectedPromotion.bonus;
    }

    const maxBonusAmount = _selectedPromotion?.max_bonus_amount;
    /**
     * if max_bonus_amount === 0 - no cap
     * else, set cap
     */
    if (parseFloat(maxBonusAmount) > 0 && bonus > parseFloat(maxBonusAmount)) {
      bonus = _selectedPromotion?.max_bonus_amount;
    }

    const minDeposit = _selectedPromotion?.min_deposit;
    /**
     * bonus only apply if deposit amount => minDeposit
     */
    if (parseFloat(depositAmountFloat) < parseFloat(minDeposit)) {
      bonus = 0;
    }

    return (
      <Grid
        container
        sx={{
          color: '#00a650',
          background: 'rgb(193, 14, 6)',
          p: '9px',
          fontSize: '14px',
          mt: '20px',
          fontWeight: 700,
          borderRadius: '10px',
          position: 'relative',
        }}
      >
        <Grid item xl={4} lg={4} md={4} sm={4} xs={6}>
          <Typography sx={{ color: 'white', fontWeight: 'inherit' }}>{translate('deposit_bonus')}</Typography>
        </Grid>

        <Grid item xl={4} lg={4} md={4} sm={4} xs={6}>
          <Typography
            sx={{ fontWeight: 'inherit', textAlign: 'center', color: '#f2c556' }}
          >{`${bonusType}`}</Typography>
        </Grid>

        <Grid item xl={4} lg={4} md={4} sm={4} xs={12}>
          <Typography
            sx={{
              color: '#f2c556',
              fontWeight: 'inherit',
              textAlign: { xl: 'end', lg: 'end', md: 'end', sm: 'end', xs: 'start' },
              wordBreak: 'break-all',
            }}
          >{`+${currency} ${fCurrency(bonus)}`}</Typography>
        </Grid>
      </Grid>
    );
  };

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text);
    enqueueSnackbar(`${translate('copied')}!`);
  };

  return (
    <>
      <Box sx={{ position: 'relative' }}>
        {showAlert.show && (
          <Box
            className="alert-pulse"
            sx={{ backgroundColor: 'rgb(255 63 63 / 70%)', p: 2, position: 'relative', mb: 1 }}
          >
            <Stack direction="row" alignItems={'center'}>
              <Typography sx={{ fontSize: '12px', fontWeight: 700, color: 'white', flexGrow: 1 }}>
                {showAlert.message}
              </Typography>
              <Iconify
                icon={'ep:close'}
                width={24}
                height={24}
                sx={{ cursor: 'pointer' }}
                onClick={() => setShowAlert({ show: false, message: '' })}
              />
            </Stack>
          </Box>
        )}

        {/* Select Payment Method Section */}

        {Object.keys(paymentMethods)?.length > 0 && (
          <FormControl sx={{ mt: '10px' }}>
            <RadioGroup
              row
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="row-radio-buttons-group"
              value={selectedPaymentMethod}
              onChange={(e) => setSelectedPaymentMethod(e.target.value)}
            >
              {Object.keys(paymentMethods).map((key, idx) => (
                <FormControlLabel
                  key={key}
                  value={key}
                  control={<Radio />}
                  label={translate(paymentMethods?.[key]?.name)}
                  componentsProps={{
                    typography: {
                      fontSize: '14px',
                      fontWeight: 700,
                      color: 'white',
                    },
                  }}
                />
              ))}
            </RadioGroup>
          </FormControl>
        )}
        {/* End Select Payment Method Section */}

        {/* Select Bank Section */}
        {bankList && (
          <Box>
            <Stack direction="row" sx={{ mt: 1, alignItems: 'end' }}>
              <span style={{ color: 'red', marginRight: '4px' }}>*</span>
              <TypographyCustom sx={{ fontWeight: 700 }}>
                {selectedPaymentMethod === 'bank' ? translate('transfer_to') : translate('payment_method')}{' '}
              </TypographyCustom>
            </Stack>
            {/* <NativeSelect
            value={selectedBank}
            sx={{ width: '100%', color: 'white', fontSize: '14px' }}
            onChange={(e) => setSelectedBank(e.target.value)}
          >
            {bankList.map((item, index) => (
              <option key={index} value={item?.BankAccount?.id} style={{ color: 'black' }}>
                {item?.BankAccount?.label}
              </option>
            ))}
          </NativeSelect> */}
            <Select
              value={selectedBank}
              sx={{ width: '100%', color: '#271111', fontSize: '14px', borderRadius: '5px' }}
              onChange={(e) => setSelectedBank(e.target.value)}
              displayEmpty
            >
              {bankList.map((item, index) => (
                <MenuItem key={index} value={item?.BankAccount?.id}>
                  {item?.BankAccount?.label}{' '}
                </MenuItem>
              ))}
            </Select>
            {errors?.selectedBank && (
              <span style={{ fontSize: '12px', color: 'yellow', fontWeight: 600 }}>{errors?.selectedBank}</span>
            )}
          </Box>
        )}
        {/* End Select Bank Section */}

        {/* Select Bank Source Section */}
        {Object.keys(bankSourceList)?.length ? (
          <Box>
            <Stack direction="row" sx={{ mt: 1, alignItems: 'end' }}>
              <span style={{ color: 'red', marginRight: '4px' }}>*</span>
              <TypographyCustom sx={{ fontWeight: 700 }}>{translate('deposit_from_bank')}</TypographyCustom>
            </Stack>
            {/* <NativeSelect
            value={selectedBank}
            sx={{ width: '100%', color: 'white', fontSize: '14px' }}
            onChange={(e) => setSelectedBank(e.target.value)}
          >
            {bankList.map((item, index) => (
              <option key={index} value={item?.BankAccount?.id} style={{ color: 'black' }}>
                {item?.BankAccount?.label}
              </option>
            ))}
          </NativeSelect> */}
            <Select
              value={selectedBankSource}
              sx={{ width: '100%', color: '#271111', fontSize: '14px', borderRadius: '5px' }}
              onChange={(e) => setSelectedBankSource(e.target.value)}
              displayEmpty
            >
              {Object.keys(bankSourceList).map((key, index) => (
                <MenuItem key={index} value={key}>
                  {bankSourceList[key]}
                </MenuItem>
              ))}
            </Select>
            {errors?.selectedBank && (
              <span style={{ fontSize: '12px', color: 'yellow', fontWeight: 600 }}>{errors?.selectedBank}</span>
            )}
          </Box>
        ) : (
          ''
        )}
        {/* End Select Bank Source Section */}

        {/* Selected Bank Info Section */}
        {selectedBank && (
          <>
            <Box
              sx={{
                background: 'rgb(193, 14, 6)',
                p: '9px',
                fontSize: '14px',
                mt: 1,
                fontWeight: 700,
                borderRadius: '10px',
              }}
            >
              <Box sx={{ display: 'flex', float: 'right' }}>
                {selectedPaymentMethod === 'bank' && (
                  <IconButton
                    size="small"
                    onClick={() => copyToClipboard(selectedBankData?.BankAccount?.account_number)}
                    sx={{ color: '#f2c556' }}
                  >
                    <Iconify icon={'ph:copy'} />
                  </IconButton>
                )}
                {selectedBankData?.BankAccount?.qrcode_photo_url ? (
                  <IconButton
                    size="small"
                    onClick={() => setShowBankQrCode(selectedBankData?.BankAccount)}
                    color="primary"
                  >
                    <Iconify icon={'ic:baseline-qrcode'} />
                  </IconButton>
                ) : (
                  ''
                )}
              </Box>
              <Box>
                {selectedPaymentMethod === 'bank' && (
                  <Typography sx={{ color: 'white', fontSize: '14px', fontWeight: 700 }}>
                    {`${translate('bank_name')}: `}
                    <Typography component="span" sx={{ color: 'white', fontSize: '14px' }}>
                      {selectedBankData?.Bank?.name}
                    </Typography>
                  </Typography>
                )}
                <Typography sx={{ color: 'white', fontSize: '14px', fontWeight: 700 }}>
                  {`${selectedPaymentMethod !== 'bank' ? translate('payment_method') : translate('account_name')}: `}
                  <Typography component="span" sx={{ color: 'white', fontSize: '14px' }}>
                    {selectedBankData?.BankAccount?.account_name}
                  </Typography>
                </Typography>
                <Typography sx={{ color: 'white', fontSize: '14px', fontWeight: 700 }}>
                  {`${selectedPaymentMethod !== 'bank' ? translate('mode') : translate('account_number')}: `}
                  <Typography component="span" sx={{ color: 'white', fontSize: '14px' }}>
                    {selectedBankData?.BankAccount?.account_number}
                  </Typography>
                </Typography>
              </Box>
            </Box>

            {/* <input
            id="upload-bank-slip"
            type="file"
            name="bankSlip"
            accept="image/*,camera/*,pdf/*"
            onChange={(e) => handleOnUpload(e)}
          /> */}
          </>
        )}
        {/* End Selected Bank Info Section */}

        {maintenanceRemark && (
          <Box
            sx={{
              width: '100%',
              backgroundColor: 'black',
              p: 1.5,
              border: '1px solid rgb(242, 197, 86)',
              borderRadius: '0.7rem',
              position: 'relative',
              my: 1,
            }}
          >
            {/* <Iconify icon={'fluent:warning-28-filled'} sx={{ fontSize: '1.2rem', color: 'rgb(242, 197, 86)', m: 0 }} /> */}
            <Box sx={{ whiteSpace: 'pre-line', fontWeight: 900 }}>{renderWithLinks(maintenanceRemark) || ''}</Box>
          </Box>
        )}

        {!isHideDeposit && ( // Hide These if Selected Bank is INACTIVE
          <>
            {/* Select Promotion Section */}
            {filteredPromotions && (
              <Box>
                <Stack direction="row" sx={{ mt: 2 }}>
                  <span style={{ color: 'red', marginRight: '4px' }}>*</span>
                  <TypographyCustom sx={{ fontWeight: 700 }}>{translate('select_bonus')}</TypographyCustom>
                </Stack>
                <Select
                  value={selectedPromotion}
                  sx={{ width: '100%', color: '#271111', fontSize: '14px', borderRadius: '5px' }}
                  onChange={(e) => setSelectedPromotion(e.target.value)}
                >
                  <MenuItem value={0}>{translate('no_promotion')}</MenuItem>
                  {filteredPromotions?.map((item, index) => (
                    <MenuItem key={index} value={item?.id}>
                      {item?.title}
                    </MenuItem>
                  ))}
                </Select>
              </Box>
            )}
            {/* End Select Promotion Section */}

            {/* Input Deposit Amount Section */}
            <Stack direction="row" sx={{ mb: 1, mt: 2 }}>
              <span style={{ color: 'red', marginRight: '4px' }}>*</span>
              <TypographyCustom sx={{ fontWeight: 700 }}>{translate('amount')}</TypographyCustom>
            </Stack>

            <Stack
              direction={{ xl: 'row', lg: 'row', md: 'row', sm: 'row', xs: 'column' }}
              sx={{ border: '1px solid #fda92d', borderRadius: '6px', p: '4px 11px', background: '#fff' }}
              alignItems={{ xl: 'center', lg: 'center', md: 'center', sm: 'center', xs: 'start' }}
            >
              <Stack direction="row" alignItems="center">
                <Typography sx={{ color: '#271111', fontSize: '20px', mr: '4px', fontWeight: 700 }}>
                  {currency}
                </Typography>
                <InputBase
                  id="outlined-adornment-weight"
                  // endAdornment={<InputAdornment position="end">kg</InputAdornment>}
                  aria-describedby="outlined-weight-helper-text"
                  sx={{ color: '#271111', flexGrow: 1, fontSize: '20px', fontWeight: 700, p: 0 }}
                  value={fNumberCommaSeparated(depositAmount)}
                  onChange={(e) => setDepositAmount(e.target.value || 0)}
                  autoFocus
                  // type="number"
                />
              </Stack>

              {selectedPromotion.toString() !== '0' && (
                <Stack
                  direction={{ xl: 'column', lg: 'column', md: 'column', sm: 'column', xs: 'row' }}
                  sx={{ width: { xl: 'unset', lg: 'unset', md: 'unset', sm: 'unset', xs: '100%' } }}
                >
                  <Typography sx={{ color: '#f2c556', fontSize: '14px', fontWeight: 700 }}>
                    {translate('extra')}
                  </Typography>
                  <Typography
                    sx={{ color: '#f2c556', fontSize: '14px', fontWeight: 700 }}
                  >{`+${currency} ${getPromotion(depositAmount)}`}</Typography>
                </Stack>
              )}
            </Stack>
            {errors?.depositAmount && (
              <span style={{ fontSize: '12px', color: 'yellow', fontWeight: 600 }}>{errors?.depositAmount}</span>
            )}

            <Box sx={{ mt: '10px' }}>
              <Grid container spacing={'20px'}>
                {overrideCurrencyTopup?.length
                  ? overrideCurrencyTopup.map((item) => makeCurrencyTopup(item))
                  : currencyTopup.map((item) => makeCurrencyTopup(item))}
              </Grid>

              {selectedPromotion.toString() !== '0' && getDisplayDepositBonus()}
            </Box>
            {/* End Input Deposit Amount Section */}

            {/* Upload Receipt Section */}
            {selectedPaymentMethod === 'bank' && selectedBank && (
              <Box sx={{ mt: 2 }}>
                <Stack direction="row">
                  <span style={{ color: 'red', marginRight: '4px' }}>*</span>
                  <TypographyCustom sx={{ fontWeight: 700 }}>{translate('upload_receipt')}:</TypographyCustom>
                </Stack>
                <UploadSingleFile
                  accept="image/*"
                  // files={bankSlip.value}
                  // error={checkError}
                  onDrop={(files) => handleFileDrop(files, 'bankSlip')}
                  // helperText={
                  //   checkError && (
                  //     <FormHelperText error sx={{ px: 2 }}>
                  //       {error?.message}
                  //     </FormHelperText>
                  //   )
                  // }
                  // {...other{{}}}
                />
                {errors?.bankSlip && (
                  <span style={{ fontSize: '12px', color: 'yellow', fontWeight: 600 }}>{errors?.bankSlip}</span>
                )}
                {bankSlip && (
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      mt: 1,
                      width: '100%',
                      justifyContent: 'center',
                    }}
                  >
                    <Card sx={{ maxWidth: '80%', mt: 1, mb: 2, height: '180px', position: 'relative' }}>
                      <Image
                        src={bankSlip?.filePreview}
                        sx={{ width: '100%', height: '100%' }}
                        alt="Preview Bank Slip"
                      />
                      <IconButton sx={{ position: 'absolute', top: 5, right: 5 }} onClick={() => setBankSlip(null)}>
                        <Iconify icon="uil:times" />
                      </IconButton>
                    </Card>
                  </Box>
                )}
                {/* End Upload Receipt Section */}

                {process.env.REACT_APP_REF_NUMBER_LENGTH > 0 && (
                  <Box sx={{ mt: 2 }}>
                    <Stack direction="row">
                      <span style={{ color: 'red', marginRight: '4px' }}>*</span>
                      <TypographyCustom sx={{ fontWeight: 700 }}>
                        {translate('last_x_digit_bank_ref_number', { x: process.env.REACT_APP_REF_NUMBER_LENGTH })}:
                      </TypographyCustom>
                    </Stack>
                    <InputBase
                      value={referenceNumber}
                      onChange={(e) => setReferenceNumber(e.target.value)}
                      id="outlined-adornment-weight"
                      placeholder={translate('last_x_digit_bank_ref_number', {
                        x: process.env.REACT_APP_REF_NUMBER_LENGTH,
                      })}
                      // endAdornment={<InputAdornment position="end">kg</InputAdornment>}
                      aria-describedby="outlined-weight-helper-text"
                      sx={{
                        color: '#271111',
                        flexGrow: 1,
                        fontSize: '1rem',
                        fontWeight: 500,
                        border: '1px solid #fda92d',
                        borderRadius: '6px',
                        background: '#fff',
                        p: '4px 11px',
                        width: '100%',
                      }}
                    />
                    {errors?.referenceNumber && (
                      <span style={{ fontSize: '12px', color: 'yellow', fontWeight: 600 }}>
                        {errors?.referenceNumber}
                      </span>
                    )}
                  </Box>
                )}
              </Box>
            )}
          </>
        )}

        {bankInactiveInstruction && (
          <Box
            sx={{
              width: '100%',
              backgroundColor: 'black',
              p: 1.5,
              border: '1px solid rgb(242, 197, 86)',
              borderRadius: '0.7rem',
              position: 'relative',
              my: 1,
            }}
          >
            {/* <Iconify icon={'fluent:warning-28-filled'} sx={{ fontSize: '1.2rem', color: 'rgb(242, 197, 86)', m: 0 }} /> */}
            <Box sx={{ whiteSpace: 'pre-line', fontWeight: '900' }}>
              {renderWithLinks(bankInactiveInstruction) || ''}
            </Box>

            {customWAButton?.url && customWAButton?.title ? (
              <Button
                fullWidth
                startIcon={<Iconify icon="ic:baseline-whatsapp" />}
                variant="contained"
                color={'inherit'}
                sx={{
                  backgroundColor: 'rgb(0, 166, 80)',
                  '&:hover': { backgroundColor: 'rgb(0, 166, 80)' },
                  color: 'white',
                  mt: 1,
                  fontWeight: '900',
                }}
                onClick={() => window.open(customWAButton.url, '_blank')}
              >
                {customWAButton.title}
              </Button>
            ) : (
              ''
            )}
          </Box>
        )}

        {!isHideDeposit && (
          <Grid
            container
            // onClick={() => toggleDrawer(true)}
            onClick={() => (isSubmitting ? {} : onSubmit())}
            sx={{
              p: '8px 12px',
              position: 'relative',
              background: 'linear-gradient(to bottom, yellow, red)',
              borderRadius: '10px',
              mt: '20px',
              cursor: isSubmitting ? 'progress' : 'pointer',
              opacity: isSubmitting ? 0.1 : 1,
            }}
          >
            <Grid
              item
              xl={3}
              lg={3}
              md={3}
              sm={3}
              xs={12}
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: { xl: 'inherit', lg: 'inherit', md: 'inherit', sm: 'inherit', xs: 'center' },
              }}
            >
              <Typography sx={{ fontSize: '18px', color: '#fff', fontWeight: 700, textShadow: '0px 1px 4px #000' }}>
                {translate('deposit')}
              </Typography>
            </Grid>
            <Grid
              item
              xl={9}
              lg={9}
              md={9}
              sm={9}
              xs={12}
              sx={{ display: 'flex', justifyContent: { xl: 'end', lg: 'end', md: 'end', sm: 'end', xs: 'center' } }}
            >
              <Stack direction="row" sx={{ alignItems: 'center' }}>
                <Typography
                  sx={{
                    fontSize: '26px',
                    color: '#fff',
                    fontWeight: 700,
                    mr: 2,
                    wordBreak: 'break-all',
                    textShadow: '0px 1px 4px #000',
                  }}
                >{`${currency} ${fCurrency(depositAmount)}`}</Typography>
                <svg
                  viewBox="64 64 896 896"
                  focusable="false"
                  data-icon="right-circle"
                  width="1em"
                  height="1em"
                  fill="currentColor"
                  aria-hidden="true"
                  style={{ color: '#fff' }}
                >
                  <path d="M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448 448-200.6 448-448S759.4 64 512 64zm154.7 454.5l-246 178c-5.3 3.8-12.7 0-12.7-6.5v-46.9c0-10.2 4.9-19.9 13.2-25.9L566.6 512 421.2 406.8c-8.3-6-13.2-15.6-13.2-25.9V334c0-6.5 7.4-10.3 12.7-6.5l246 178c4.4 3.2 4.4 9.8 0 13z" />
                </svg>
              </Stack>
            </Grid>
          </Grid>
        )}
      </Box>

      <PopupQrCode
        qrCodeString={topPayQrCode}
        setTopPayQrCode={setTopPayQrCode}
        bankName={selectedBankData?.Bank?.name || ''}
      />

      <Dialog
        fullScreen
        open={!!iFrameLink}
        onClose={() => {
          setIFramLink(null);
          dispatch(openTransactionsDialog({ open: false, isDeposit: true }));
        }}
        PaperProps={{
          sx: {
            margin: '0px !important',
          },
        }}
      >
        <Stack sx={{ py: 1, px: 1, alignItems: 'center', height: '100%' }} spacing={2}>
          <Iconify
            onClick={() => {
              setIFramLink(null);
              dispatch(openTransactionsDialog({ open: false, isDeposit: true }));
            }}
            icon={'carbon:close-filled'}
            sx={{ color: 'warning.main', height: '25px', width: '25px', ml: 'auto', cursor: 'pointer' }}
          />
          <iframe src={iFrameLink} style={{ height: '100%', width: '100%' }} title="Iframe Popup" />
          <Button
            onClick={() => {
              setIFramLink(null);
              dispatch(openTransactionsDialog({ open: false, isDeposit: true }));
            }}
            variant="contained"
            color="warning"
          >
            {translate('close')}
          </Button>
        </Stack>
      </Dialog>

      <CustomModal maxWidth="xs" open={!!showBankQrCode} onClose={() => setShowBankQrCode(null)}>
        <Stack sx={{ py: 5, px: 4, alignItems: 'center' }} spacing={2}>
          <Card sx={{ bgColor: 'white' }}>
            <Image src={showBankQrCode?.qrcode_photo_url} />
          </Card>

          <Typography textAlign={'center'}>{showBankQrCode?.label}</Typography>
        </Stack>
      </CustomModal>
    </>
  );
};

export default Deposit;
