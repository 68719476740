import { S3_ASSETS_URL } from '../config';

// export const appLocale = (() => {
//   const lsLocales = localStorage.getItem('i18nextLng') || 'en';
//   // if (lsLocales === 'ms') return 'my';
//   // if (lsLocales === 'my') return 'mm';
//   // Temporary th assets not yet avail
//   if (lsLocales === 'th') return 'en';
//   return lsLocales;
// })();

export const appLocale = (() => localStorage.getItem('i18nextLng') || 'en')();

// const gameMenuFolder = `${S3_ASSETS_URL}home/game_menu/${appLocale ? `${appLocale}/` : ''}`;
const gameMenuFolder = ``;

export const GAME_CATEGS = [
  {
    type: 'PP',
    name: 'Hot Games',
    subname: 'Hot Games',
    categSlug: 'popular',
    btnImageUrl: require('../assets/home/gameCategories/popular.png'),
    btnImageMobile: require('../assets/home/gameCategories/popular.png'),
    subIcon: `${S3_ASSETS_URL}home/Sub-Casino.webp`,
  },
  {
    type: 'IG',
    name: 'Instant Game',
    subname: 'Instant Game',
    categSlug: 'instant-game',
    btnImageUrl: require('../assets/home/gameCategories/instant-game.png'),
    btnImageMobile: require('../assets/home/gameCategories/instant-game.png'),
    subIcon: `${S3_ASSETS_URL}home/Sub-Casino.webp`,
  },
  {
    type: 'LC',
    name: 'Casino',
    subname: 'Live Casino',
    categSlug: 'live-casino',
    // btnImageUrl: `${gameMenuFolder}Casino-x.webp`,
    // btnImageMobile: `${gameMenuFolder}Mobile-Menu-Casino.gif`,
    btnImageUrl: require('../assets/home/gameCategories/live-casino.png'),
    btnImageMobile: require('../assets/home/gameCategories/live-casino.png'),
    subIcon: `${S3_ASSETS_URL}home/Sub-Casino.webp`,
  },
  {
    type: 'SL',
    name: 'Slots',
    subname: 'Slots',
    categSlug: 'slots',
    // btnImageUrl: `${gameMenuFolder}Slot-x.webp`,
    // btnImageMobile: `${gameMenuFolder}Mobile-Menu-Slot.gif`,
    btnImageUrl: require('../assets/home/gameCategories/slots-icon.png'),
    btnImageMobile: require('../assets/home/gameCategories/slots-icon.png'),
    subIcon: `${S3_ASSETS_URL}home/Sub-Slot.webp`,
  },
  {
    type: 'SB',
    name: 'Sports',
    subname: 'Sports',
    categSlug: 'sports',
    // btnImageUrl: `${gameMenuFolder}Sport-x.webp`,
    // btnImageMobile: `${gameMenuFolder}Mobile-Menu-Sport.gif`,
    btnImageUrl: require('../assets/home/gameCategories/sports-icon.png'),
    btnImageMobile: require('../assets/home/gameCategories/sports-icon.png'),
    subIcon: `${S3_ASSETS_URL}home/Sub-Sport.webp`,
  },
  {
    type: 'CG',
    name: 'Card Game',
    subname: 'Card Game',
    categSlug: 'card-game',
    // btnImageUrl: `${gameMenuFolder}cardx.webp`,
    // btnImageMobile: `${gameMenuFolder}Mobile-Menu-Card1.webp`,
    btnImageUrl: require('../assets/home/gameCategories/card-game.png'),
    btnImageMobile: require('../assets/home/gameCategories/card-game.png'),
    subIcon: `${S3_ASSETS_URL}home/Sub-Card.webp`,
  },
  {
    type: 'FH',
    name: 'Fishing',
    subname: 'Fishing',
    categSlug: 'fishing',
    // btnImageUrl: `${gameMenuFolder}fishing.webp`,
    // btnImageMobile: `${gameMenuFolder}Mobile-Menu-Fishing.webp`,
    btnImageUrl: require('../assets/home/gameCategories/fishing.png'),
    btnImageMobile: require('../assets/home/gameCategories/fishing.png'),
    subIcon: `${S3_ASSETS_URL}home/Sub-Fish.webp`,
  },
  // {
  //   type: 'ES',
  //   name: 'E-Sports',
  //   subname: 'E-Sports',
  //   btnImageUrl: `${gameMenuFolder}Esports-x.webp`,
  //   btnImageMobile: `${gameMenuFolder}Mobile-Menu-Esports.webp`,
  //   subIcon: `${S3_ASSETS_URL}home/banners/MBanner-Play.webp`
  // },
];

// Refer to public/loadNewTab.html
export const launchGameNewTabHtml = `
<html lang="en">
<head>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title>Loading Game</title>
</head>
<style>
    body{
        background-image: url('${require('../assets/home/game-bg.png')}');
        background-repeat: no-repeat;
        background-attachment: fixed;
        background-size: 100% 100%;
        background-position: center;
    }
    .container {
        width: 100%;
        height: 100%;
        font-family: Roboto, sans-serif;
        font-size: 2rem;
        color: white;
        font-weight: 700;
        /* margin-top: 5rem; */
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .gold-text {
        background-image: linear-gradient(#eec85c, #a5732c);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }

    @media (max-width: 767px) {
      /* Your styles go here */
      body {
        background-image: url('${require('../assets/home/game-bg-mobile.png')}');
      }
    }
</style>
<body>
    <div class="container gold-text">
        Loading game site...
    </div>
</body>
</html>
`;
