import * as Yup from 'yup';
import { useState } from 'react';
// form
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// import {
//   loadCaptchaEnginge,
//   LoadCanvasTemplateNoReload,
//   validateCaptcha,
// } from 'react-simple-captcha';
// @mui
import { Stack, Alert, IconButton, InputAdornment, Link, Box, styled } from '@mui/material';
// hooks
import { LoadingButton } from '@mui/lab';
import useAuth from '../../../hooks/useAuth';
// components
import Iconify from '../../../components/Iconify';
import { FormProvider, RHFPhonenumber, RHFTextField } from '../../../components/hook-form';
import { closeLogin } from '../../../redux/slices/authPages';
import { useDispatch } from '../../../redux/store';
import useLocales from '../../../hooks/useLocales';
import useRedirect from '../../../hooks/useRedirect';
import { getCountryFromUrl } from '../../../utils/formatString';

// ----------------------------------------------------------------------
const FormContainer = styled(Box)(({ theme }) => ({
  background: 'linear-gradient(45deg, rgba(255,191,102,1) 0%, rgba(255,191,102,1) 20%, rgba(255,64,62,1) 40%)',
  padding: '16px',
  borderRadius: '10px',
  border: '2px solid red',
  '& .MuiFormHelperText-root': {
    color: 'yellow !important',
  },
}));

export default function LoginForm() {
  const country = getCountryFromUrl();

  const { login } = useAuth();

  const { translate } = useLocales();

  const dispatch = useDispatch();

  const navigate = useRedirect();

  const [showPassword, setShowPassword] = useState(false);

  const LoginSchema = Yup.object().shape({
    username: Yup.string().required(translate('x_is_required', { x: translate('phone_number') })),
    password: Yup.string().required(translate('x_is_required', { x: translate('password') })),
    // captcha: Yup.string().required(translate('x_is_required', { x: translate('captcha') })),
  });

  const defaultValues = {
    username: '',
    password: '',
    captcha: '',
  };

  const methods = useForm({
    resolver: yupResolver(LoginSchema),
    defaultValues,
  });

  const {
    reset,
    setError,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = methods;

  const onSubmit = async (data) => {
    const requestData = {
      username: data?.username.replace(/\D/g, ''),
      password: data?.password,
    };

    try {
      const newData = JSON.parse(JSON.stringify(requestData));
      // if (!validateCaptcha(data?.captcha)) throw new Error(translate('invalid_captcha'));

      // delete newData.captcha;

      await login(newData);
      dispatch(closeLogin());
    } catch (error) {
      console.error(error);

      // reset();

      // if (isMountedRef.current) {
      setError('afterSubmit', { ...error, message: translate(error.message) });
      // }
    }
  };

  // useEffect(() => {
  //   loadCaptchaEnginge(4);
  // }, []);

  const doSubmit = () => {
    // <! --   let's assume there is an HTML input text box with id 'user_captcha_input' to get user input -->
    // const user_captcha_value = document.getElementById('user_captcha_input').value;
    // if (validateCaptcha(user_captcha_value) == true) {
    //   alert('Captcha Matched');
    // } else {
    //   alert('Captcha Does Not Match');
    // }
  };

  return (
    <FormContainer>
      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <Stack spacing={2} sx={{ width: '100%' }}>
          {!!errors.afterSubmit && <Alert severity="error">{errors.afterSubmit.message}</Alert>}

          <RHFPhonenumber
            name="username"
            sx={{
              '& .MuiOutlinedInput-root': {
                paddingLeft: 0,
              },
            }}
            // InputProps={{
            //   startAdornment: (
            //     <InputAdornment position="start">
            //       <Iconify icon="ic:baseline-phone" sx={{ fontSize: '20px', color: 'red' }} />
            //     </InputAdornment>
            //   ),
            // }}
          />
          <RHFTextField
            name="password"
            // label={translate('password')}
            sx={{
              '& .MuiOutlinedInput-root': {
                paddingLeft: 0,
              },
            }}
            placeholder={translate('password')}
            type={showPassword ? 'text' : 'password'}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Iconify icon="material-symbols:lock" sx={{ fontSize: '20px', color: 'red' }} />
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={() => setShowPassword(!showPassword)} edge="end" tabIndex={-1}>
                    <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />

          {/* <RHFTextField
            name="captcha"
            // label={translate('captcha')}
            placeholder={translate('captcha')}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <LoadCanvasTemplateNoReload />
                </InputAdornment>
              ),
            }}
          /> */}

          <Box sx={{ width: '100%' }}>
            <Link
              onClick={() => {
                navigate('/auth/forgot-password');
              }}
              sx={{
                textDecoration: 'underline',
                color: 'white',
                fontSize: '12px',
                textAlign: 'center',
                textShadow: '0px 1px 4px #000',
              }}
            >
              {`${translate('forgot_password')}?`}
            </Link>
          </Box>

          {/* <Box>
            <Stack alignItems="center" justifyContent="space-between" spacing={1}> */}
          <LoadingButton
            fullWidth
            type="submit"
            variant="contained"
            className="red-gradient-btn"
            sx={{ textShadow: '0px 1px 4px #000' }}
            loading={isSubmitting}
          >
            {translate('submit')}
          </LoadingButton>

          <Link
            onClick={() => {
              navigate('/auth/register');
            }}
            sx={{
              textDecoration: 'underline',
              color: 'white',
              fontSize: '12px',
              textAlign: 'center',
              textShadow: '0px 1px 4px #000',
            }}
          >
            {`${translate('register')}`}
          </Link>
          {/* </Stack>
          </Box> */}
        </Stack>
      </FormProvider>
    </FormContainer>
  );
}
