import React, { useState, useEffect } from 'react';
import { Outlet, useParams } from 'react-router-dom';
// @mui
import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';
import ReactGA from 'react-ga4';
import ReactPixel from 'react-facebook-pixel';
import { LiveChatWidget } from '@livechat/widget-react';

// hooks
import useResponsive from '../../hooks/useResponsive';
import useCollapseDrawer from '../../hooks/useCollapseDrawer';
// config
import { HEADER, NAVBAR } from '../../config';
// redux
import { useDispatch, useSelector } from '../../redux/store';

//
import DashboardHeader from './header';
import NavbarVertical from './navbar/NavbarVertical';

import MobileNav from './navbar/MobileNav';
import {
  getGameCategsAndCompanies,
  getPromotions,
  getSiteSettings,
  getUserMemberBalance,
} from '../../redux/slices/lookup';

import useAuth from '../../hooks/useAuth';
import { usePageVisibility } from '../../hooks/usePageVisibility';
import DepositWithdrawDialog from './navbar/DepositWithdrawDialog';
import WithdrawFormDialog from './navbar/withdraw/WithdrawFormDialog';
import useLocales from '../../hooks/useLocales';
import { closeLoadingScreen, openLoadingScreen } from '../../redux/slices/settings';

// ----------------------------------------------------------------------

const MainStyle = styled('main', {
  shouldForwardProp: (prop) => prop !== 'collapseClick',
})(({ collapseClick, theme }) => ({
  flexGrow: 1,
  paddingTop: HEADER.MOBILE_HEIGHT,
  paddingBottom: '60px',
  [theme.breakpoints.up('lg')]: {
    paddingTop: HEADER.DASHBOARD_DESKTOP_HEIGHT,
    width: `calc(100% - ${NAVBAR.DASHBOARD_WIDTH})`,
    transition: theme.transitions.create('margin-left', {
      duration: theme.transitions.duration.shorter,
    }),
  },
  [theme.breakpoints.down('lg')]: {
    paddingBottom: HEADER.MOBILE_HEIGHT + 8,
  },
}));

function updatePos(elem, width, widgetState) {
  if (width < 600 && widgetState === 'widgetClose') {
    elem.style.setProperty('bottom', '25vw', 'important');
    elem.style.setProperty('right', '2vw', 'important');
    elem.style.opacity = 1;
  } else if (width < 600 && widgetState === 'widgetOpen') {
    elem.style.setProperty('bottom', '0px', 'important');
    elem.style.setProperty('right', '0px', 'important');
    elem.style.opacity = 1;
  } else {
    elem.style.setProperty('bottom', '70px', 'important');
    elem.style.setProperty('right', '70px', 'important');
    elem.style.opacity = 1;
  }
}

// ----------------------------------------------------------------------

const DashboardLayout = () => {
  const { isAuthenticated } = useAuth();

  const { onChangeLang } = useLocales();

  const { collapseClick, isCollapse, onToggleCollapse } = useCollapseDrawer();

  const { isOpenTransactionsDialog } = useSelector((x) => x.transactionsDialog);

  const { isAddMemberBankDialog } = useSelector((x) => x.addMemberBankDialog);

  const { siteSettings } = useSelector((x) => x.lookup);

  const { isOpenLogin, isOpenRegister, isOpenForgotPwd, isOpenChangePwd } = useSelector((x) => x.authPages);

  const dispatch = useDispatch();

  const isSm = useResponsive('down', 'md');

  const [open, setOpen] = useState(false);

  const [element, setElement] = useState(null);

  const isVisible = usePageVisibility();

  const params = useParams();

  const [width, setWidth] = useState(window.innerWidth);
  const [height, setHeight] = useState(window.innerHeight);

  const updateDimensions = () => {
    setWidth(window.innerWidth);
    setHeight(window.innerHeight);
  };
  useEffect(() => {
    window.addEventListener('resize', updateDimensions);
    return () => window.removeEventListener('resize', updateDimensions);
  }, []);

  useEffect(() => {
    const isShowLoadingScreen = !localStorage.getItem(`siteSettings-${params?.domain}`);
    const promises = [getGameCategsAndCompanies(), getSiteSettings(), getPromotions()];

    if (isShowLoadingScreen) dispatch(openLoadingScreen());
    Promise.all(promises).finally(() => {
      if (isShowLoadingScreen) dispatch(closeLoadingScreen());
    });
  }, [params?.domain, isAuthenticated]);

  useEffect(() => {
    try {
      if (siteSettings?.config_analytics) {
        const _ga = siteSettings?.config_analytics?.split(',');

        // Multiple products (previously known as trackers)
        ReactGA.initialize(
          _ga.map((item) => ({
            // measurement ID
            trackingId: item,
            // gaOptions: {...}, // optional
            // gtagOptions: {...}, // optional
          }))
          //   [
          //   {
          //     // measurement ID
          //     trackingId: _ga[0],
          //     // gaOptions: {...}, // optional
          //     // gtagOptions: {...}, // optional
          //   },
          // ]
        );
      }

      if (
        siteSettings?.config_livechat &&
        (siteSettings?.config_livechat?.indexOf('respondio') !== -1 ||
          siteSettings?.config_livechat?.indexOf('respond.io') !== -1)
      ) {
        const respondIo = JSON.parse(siteSettings?.config_livechat);
        const existingScript = document.getElementById(respondIo?.id);

        if (!existingScript) {
          const script = document.createElement('script');
          script.id = respondIo?.id;
          script.src = respondIo?.src;
          script.async = true;

          document.head.appendChild(script);
        }
      }

      if (siteSettings?.config_fb_pixel) {
        const metaPixel = siteSettings.config_fb_pixel.replace(/[^\d,]+/g, '').split(',');
        const options = {
          autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
          debug: false, // enable logs
        };

        metaPixel.forEach((_pixel) => {
          ReactPixel.init(_pixel, options);

          ReactPixel.pageView(); // For tracking page view
        });
      }
    } catch (error) {
      console.warn(`THIRD PARTY INTEGRATION ERROR: ${error?.message}`);
    }
  }, [siteSettings]);

  useEffect(() => {
    if (isAuthenticated && isVisible) getUserMemberBalance(true);
    if (isVisible) {
      if (!localStorage.getItem(`lang${params?.domain?.toUpperCase()}`)) {
        localStorage.setItem(`lang${params?.domain?.toUpperCase()}`, 'en');
        onChangeLang('en');
      } else {
        onChangeLang(localStorage.getItem(`lang${params?.domain?.toUpperCase()}`));
      }
    }
  }, [isVisible, isAuthenticated]);

  useEffect(() => {
    // const selector = 'iframe';

    const selector = '#_68625 > div > iframe';

    let observer;

    function handleMutation(mutations) {
      if (document.querySelector(selector)) {
        observer?.disconnect();
        setElement(document.querySelector(selector));
      }
      if (document.querySelector('#_68625'))
        document.querySelector('#_68625').style.setProperty('z-index', '1299', 'important');

      if (document.querySelector('html > respond-io-widget')?.shadowRoot?.querySelector('div > div'))
        document
          .querySelector('html > respond-io-widget')
          .shadowRoot.querySelector('div > div')
          .style.setProperty('z-index', '1299', 'important');
    }

    if (document.querySelector(selector)) {
      setElement(document.querySelector(selector));
    } else {
      const observer = new MutationObserver(handleMutation);
      observer?.observe(document.body, {
        childList: true,
        subtree: true,
      });

      return () => {
        observer?.disconnect(); // Cleanup when the component unmounts
      };
    }
  }, []); // The empty dependency array ensures this effect runs only once

  useEffect(() => {
    if (element) {
      console.log('iframe Element is ready');
      element.style.opacity = 0;

      // it seems their js sets the bottom position with a slight delay after load, so we wait 1 sec then only set our css
      setTimeout(() => {
        const _widgetState = element?.getAttribute('state');
        updatePos(element, width, _widgetState);

        // observe attribute change
        const observer = new MutationObserver((mutations) => {
          mutations.forEach((mutation) => {
            if (mutation.type === 'attributes') {
              const widgetState = element?.getAttribute('state');

              console.log(`widgetState = ${widgetState}`);

              console.log('attributes changed');
              // set the css again
              updatePos(element, width, widgetState);
            }
          });
        });

        observer.observe(element, {
          attributes: true, // configure it to listen to attribute changes
        });
      }, 1000);
    }
  }, [element]);

  return (
    <Box
      sx={{
        display: { md: 'flex', lg: 'flex' },
        minHeight: { lg: 1 },
      }}
    >
      {/* {process.env.REACT_APP_IS_DEV && (
        <Box
          sx={{
            position: 'fixed',
            left: 0,
            width: '40px',
            height: '40px',
            top: 0,
            overflow: 'hidden',
            borderRadius: '0px',
            zIndex: 99999,
          }}
        >
          <Box sx={{ transform: 'rotateX(0deg) rotateY(180deg) rotate(0deg)' }}>
            <Box
              sx={{
                //   borderImage: 'linear-gradient(to bottom, red, rgba(0, 0, 0, 0)) 1 100%',
                borderRight: '40px solid #d60000',
                borderBottom: '40px solid transparent',
                position: 'relative',
                top: '-20px',
                zIndex: 1,
              }}
            />
          </Box>
        </Box>
      )} */}
      <DashboardHeader
        isCollapse={isCollapse}
        isOpenSidebar={open}
        onOpenSidebar={() => setOpen(true)}
        onCloseSidebar={() => setOpen(!open)}
        onToggleCollapse={onToggleCollapse}
      />

      <NavbarVertical isOpenSidebar={open} onCloseSidebar={() => setOpen(false)} />

      <MainStyle>
        <Outlet />
      </MainStyle>

      {/* <CustomModal
        maxWidth={'xs'}
        open={isOpenForgotPwd}
        onClose={() => dispatch(closeForgotPwd())}
        children={<ForgotPassword />}
      />

      <CustomModal
        maxWidth={'xs'}
        open={isOpenChangePwd}
        onClose={() => dispatch(closeChangePwd())}
        children={<ChangePassword />}
      /> */}

      <MobileNav />

      {isOpenTransactionsDialog.open && <DepositWithdrawDialog />}

      {isAddMemberBankDialog?.open && <WithdrawFormDialog />}

      {siteSettings?.config_livechat && siteSettings?.config_livechat?.length === 8 ? (
        <LiveChatWidget
          license={siteSettings?.config_livechat}
          onReady={(data) => {
            const widgetContainer = document.getElementById('chat-widget-container');

            if (widgetContainer && data.state.visibility === 'minimized') {
              widgetContainer.classList.add('adjust-bottom-space');
            }
          }}
          onVisibilityChanged={(data) => {
            const widgetContainer = document.getElementById('chat-widget-container');

            if (widgetContainer) {
              if (data.visibility === 'maximized') {
                widgetContainer.classList.remove('adjust-bottom-space');
              } else widgetContainer.classList.add('adjust-bottom-space');
            }
          }}
        />
      ) : (
        <></>
      )}
    </Box>
  );
};

export default DashboardLayout;
