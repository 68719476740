import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import useSettings from './useSettings';
// config
import { allLangs, defaultLang } from '../config';
import { fToSnake } from '../utils/formatString';

// ----------------------------------------------------------------------

export default function useLocales() {
  const { i18n, t: translate } = useTranslation();

  const params = useParams();

  const { onChangeDirectionByLang } = useSettings();

  const defaultCountry = process?.env?.REACT_APP_SUB_DOMAINS ? process?.env?.REACT_APP_SUB_DOMAINS?.split(',')[0] : '';

  const langStorage = localStorage.getItem('LngDomain') || params?.domain || defaultCountry;

  const currentLang = allLangs.find((_lang) => _lang?.domain === langStorage);

  const handleChangeLanguage = (newlang) => {
    i18n.changeLanguage(newlang);
    onChangeDirectionByLang(newlang);
  };

  const handleTranslate = (text, options) => {
    let translated = text;

    const objKey = fToSnake(translated);

    if (i18n.exists(objKey)) {
      translated = translate(objKey, options);
    }

    return translated;
  };

  return {
    onChangeLang: handleChangeLanguage,
    translate: handleTranslate,
    currentLang,
    allLangs,
  };
}
