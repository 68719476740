import PropTypes from 'prop-types';
import { useDropzone } from 'react-dropzone';
// @mui
import { styled } from '@mui/material/styles';
import { Box, CardMedia, Typography } from '@mui/material';
//
import Image from '../Image';
import RejectionFiles from './RejectionFiles';
import BlockContent from './BlockContent';
import cssStyles from '../../utils/cssStyles';
import Iconify from '../Iconify';
import { ALLOWED_FILE_TYPES } from '../../config';

// ----------------------------------------------------------------------

const DropZoneStyle = styled('div')(({ theme }) => ({
  outline: 'none',
  overflow: 'hidden',
  position: 'relative',
  padding: theme.spacing(5, 1),
  borderRadius: theme.shape.borderRadius,
  transition: theme.transitions.create('padding'),
  // backgroundColor: theme.palette.background.neutral,
  border: `1px dashed ${theme.palette.grey[500_32]}`,
  '&:hover': { opacity: 0.72, cursor: 'pointer' },
}));

const PreviewStyle = styled(Box)(({ theme }) => ({
  ...cssStyles().bgBlur({ blur: 2, color: theme.palette.grey[900] }),
  top: 8,
  left: 8,
  borderRadius: 10,
  position: 'absolute',
  width: 'calc(100% - 16px)',
  height: 'calc(100% - 16px)',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  // color: theme.palette.common.white,
}));

const UploadPreview = ({ file }) => {
  if (!file) return [];

  if (ALLOWED_FILE_TYPES.image.indexOf(file.type) === -1) {
    return (
      <PreviewStyle>
        <Iconify icon="akar-icons:file" fontSize={50} color={'primary.main'} />
        <Typography variant="subtitle1">{file?.name || file?.path || 'FILE'}</Typography>
      </PreviewStyle>
    );
  }

  return (
    <Image
      alt="file preview"
      src={typeof file === 'string' ? file : file.preview}
      sx={{
        top: 8,
        left: 8,
        borderRadius: 1,
        position: 'absolute',
        width: 'calc(100% - 16px)',
        height: 'calc(100% - 16px)',
      }}
    />
  );
};

// ----------------------------------------------------------------------

UploadSingleFile.propTypes = {
  error: PropTypes.bool,
  file: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  helperText: PropTypes.node,
  sx: PropTypes.object,
};

export default function UploadSingleFile({ error = false, file, helperText, sx, ...other }) {
  const { getRootProps, getInputProps, isDragActive, isDragReject, fileRejections } = useDropzone({
    multiple: false,
    ...other,
  });

  return (
    <Box sx={{ width: '100%', ...sx }}>
      <DropZoneStyle
        {...getRootProps()}
        sx={{
          ...(isDragActive && { opacity: 0.72 }),
          ...((isDragReject || error) && {
            color: 'error.main',
            borderColor: 'error.light',
            bgcolor: 'error.lighter',
          }),
          ...(file &&
            other.showPreview && {
              padding: '12% 0',
            }),
        }}
      >
        <input {...getInputProps()} />

        <BlockContent />

        {other.showPreview && <UploadPreview file={file} />}
      </DropZoneStyle>

      {fileRejections.length > 0 && <RejectionFiles fileRejections={fileRejections} maxSize={other.maxSize} />}

      {helperText && helperText}
    </Box>
  );
}
