import PropTypes from 'prop-types';
// form
import { useFormContext, Controller } from 'react-hook-form';
// @mui
import { TextField } from '@mui/material';
import { DatePicker, DateTimePicker } from '@mui/lab';

// ----------------------------------------------------------------------

RHFDatePicker.propTypes = {
  name: PropTypes.string,
  InputSx: PropTypes.object,
};

export default function RHFDatePicker({ name, InputSx = {}, ...other }) {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <DatePicker
          name={name}
          inputFormat="dd/MM/yyyy"
          {...other}
          renderInput={(params) => (
            <TextField
              sx={{ ...InputSx }}
              {...params}
              //   onChange={(e) => console.log(e.target.value)}
              fullWidth
              variant="outlined"
              size="small"
              error={!!error}
              helperText={error?.message}
            />
          )}
          {...field}
        />
      )}
    />
  );
}

RHFDateTimePicker.propTypes = {
  name: PropTypes.string,
};

export function RHFDateTimePicker({ name, ...other }) {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <DateTimePicker
          name={name}
          inputFormat="dd/MM/yyyy hh:mm a"
          {...other}
          renderInput={(params) => (
            <TextField
              {...params}
              //   onChange={(e) => console.log(e.target.value)}
              fullWidth
              variant="outlined"
              size="small"
              error={!!error}
              helperText={error?.message}
            />
          )}
          {...field}
        />
      )}
    />
  );
}
