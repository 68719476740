import { createSlice } from '@reduxjs/toolkit';

// ----------------------------------------------------------------------

const initialState = {
  isOpenTransactionsDialog: {
    open: false,
    isDeposit: true,
    promotionID: null
  },
};

const slice = createSlice({
  name: 'transactionsDialog',
  initialState,
  reducers: {
    openTransactionsDialog(state, action) {
      state.isOpenTransactionsDialog = {
        open: action?.payload?.open,
        isDeposit: action?.payload?.isDeposit,
        promotionID: action?.payload?.promotionID
      };
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const { openTransactionsDialog } = slice.actions;
