import PropTypes from 'prop-types';
// form
import { useFormContext, Controller } from 'react-hook-form';
import PhonenumberTextField from '../PhonenumberTextField';

// ----------------------------------------------------------------------

RHFPhonenumber.propTypes = {
  name: PropTypes.string,
};

export default function RHFPhonenumber({ name, ...other }) {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <PhonenumberTextField {...field} fullWidth size='small' error={!!error} helperText={error?.message} {...other} />
      )}
    />
  );
}
