// ----------------------------------------------------------------------

// IF THIS TRANSLATION IS INCORRECT PLEASE IGNORE THIS AS THIS TRANSLATION IS FOR DEMO PURPOSES ONLY
// We are happy if you can help improve the translation by sending an email to support@minimals.cc.

// ----------------------------------------------------------------------

const bn = {
  // login
  login: `বিঃদ্রঃ`,

  // register
  register: `নিবন্ধন`,

  // home
  game_partners: `খেলা অংশীদার`,
  responsible_gaming: `দায়িত্বশীল গেমিং`,
  supported_bank: `সমর্থিত ব্যাংক`,
  load_more: `আর ঢুকাও`,

  // game
  choose_your_favourite_x_games: `আপনার পছন্দের {{x}}টি গেম বেছে নিন`,
  choose_your_favourite_x: `Choose Your Favourite {{x}}`,
  launch: `শুরু করা`,

  // notification
  notifications: `বিজ্ঞপ্তি`,
  you_have_x_unread_messages: `আপনার {{x}}টি অপঠিত বার্তা আছে৷`,

  // deposit/withdraw
  drop_or_select_file: `ড্রপ বা সিলেক্ট ফাইল`,
  drop_files_here: `এখানে ফাইল ড্রপ করুন`,
  bank: `ব্যাংক`,
  bank_name: `ব্যাংকের নাম`,
  account_name: `হিসাবের নাম`,
  account_number: `হিসাব নাম্বার`,
  bank_account: `ব্যাংক হিসাব`,
  something_went_wrong: `কিছু ভুল হয়েছে. সমর্থন যোগাযোগ করুন.`,
  deposit_amount_between_x_and_y: `জমার পরিমাণ অবশ্যই {{x}} এবং {{y}} এর মধ্যে হতে হবে`,
  minimum_amount_is_x_y: `সর্বনিম্ন পরিমাণ হল {{x}}{{y}}`,
  payment_method: `মূল্যপরিশোধ পদ্ধতি`,
  mode: `মোড`,
  transfer_to: `স্থানান্তর করা`,
  select: `নির্বাচন করুন`,
  account: `হিসাব`,
  amount: `পরিমাণ`,
  deposit: `জমা`,
  withdraw: `প্রত্যাহার করুন`,
  amount_no_exceed_balance: `প্রত্যাহারের পরিমাণ উপলব্ধ ব্যালেন্সের বেশি হওয়া উচিত নয়`,
  minimum_withdraw_amount_is_x: `সর্বনিম্ন উত্তোলনের পরিমাণ হল {{x}}`,
  maximum_withdraw_amount_is_x: `সর্বাধিক উত্তোলনের পরিমাণ হল {{x}}`,
  available_balance: `পর্যাপ্ত টাকা`,
  amount_withdrawn: `টাকা প্রত্যাহার করা হয়েছে`,
  no_bank_account: `প্রত্যাহার করার জন্য আপনার কোনো অ্যাকাউন্ট নেই, এখনই যোগ করুন`,
  add_account: `হিসাব যোগ করা`,
  account_name_must_be_4: `অ্যাকাউন্টের নাম দৈর্ঘ্যে 4-এর বেশি হতে হবে`,
  please_select_bank: `একটি ব্যাঙ্ক অ্যাকাউন্ট নির্বাচন করুন`,
  account_number_must_be_x: `অ্যাকাউন্ট নম্বর দৈর্ঘ্যে 10-14 হতে হবে`,
  minimum_length_is_x: `সর্বনিম্ন দৈর্ঘ্য হল {{x}}`,
  maximum_length_is_x: `সর্বাধিক দৈর্ঘ্য হল {{x}}`,
  withdraw_information: `তথ্য প্রত্যাহার`,
  account_holder_name: `হিসাব দাতার নাম`,
  name_of_the_account_holder: `অ্যাকাউন্টধারীর নাম`,
  fill_in_bank_account: `ব্যাঙ্ক অ্যাকাউন্ট পূরণ করুন`,
  please_upload_receipt: `অনুগ্রহ করে রসিদ আপলোড করুন`,
  deposit_bonus: `ডিপোজিট বোনাস`,
  bank_transfer: `ব্যাংক লেনদেন`,
  upload_receipt: `রসিদ আপলোড করুন`,
  select_bonus: `বোনাস নির্বাচন করুন`,
  no_promotion: `কোন প্রচার নেই`,
  network_error: 'নেটওয়ার্ক ত্রুটি',
  we_have_sent_otp: 'আমরা আপনার ফোন নম্বরে OTP প্রেরণ করেছি',
  your_otp_expire: 'আপনার OTP মেয়াদ উত্তীর্ণ হবে',
  didnt_receive: 'মেসেজ পেলেন না?',
  send_again: 'আবার পাঠান',
  resend_again: 'আবার পাঠান মুলতুবি',
  invalid_verification: 'অবৈধ যাচাই কোড!',
  otp_expired: 'OTP মেয়াদ উত্তীর্ণ হয়েছে, দয়া করে আবার ফোন নম্বর প্রবেশ করুন এবং আবার চেষ্টা করুন!',
  otp_expired_redirect: 'OTP মেয়াদ উত্তীর্ণ হয়েছে! লগইনে পুনঃনির্দেশনা দেওয়া হচ্ছে',
  captcha_check_failed: 'ক্যাপচা চেক ব্যর্থ!',
  verify_phonenumber: 'ফোন নম্বর যাচাই করুন',
  send_otp: 'OTP পাঠান',
  success_verification: 'আপনার ফোন নম্বর যাচাই করা হয়েছে! আপনার নিবন্ধন শেষ করতে নিচের ফর্ম পূরণ করুন।',

  // transaction history
  transaction_history: `আপনি কি আমার সাথে কি করতে চান`,

  // bet history
  bet_history: `বাজি ইতিহাস`,
  game_provider: `গেম প্রদানকারী`,
  bet_count: `বাজি গণনা`,
  bet_amount: `বাজির পরিমাণ`,
  win_loss: `জয়/পরাজয়`,
  rebate: `রিবেট`,
  rebates: `রিবেট`,
  turnover: `টার্নওভার`,
  game_category: `খেলা বিভাগ`,

  // all
  all: `সব`,
  x_is_required: `{{x}} প্রয়োজন৷`,
  note: `বিঃদ্রঃ`,
  save: `সংরক্ষণ`,
  please_select_x: `অনুগ্রহ করে {{x}} নির্বাচন করুন`,
  title: `শিরোনাম`,
  date: `তারিখ`,
  type: `টাইপ`,
  status: `স্ট্যাটাস`,
  remark: `মন্তব্য`,
  clear: `পরিষ্কার`,
  date_from: `তারিখ থেকে`,
  date_to: `তারিখ`,
  category: `শ্রেণী`,
  search: `অনুসন্ধান করুন`,
  new: `নতুন`,
  change_password: `পাসওয়ার্ড পরিবর্তন করুন`,
  logout: `প্রস্থান`,
  username: `ব্যবহারকারীর নাম`,
  password: `পাসওয়ার্ড`,
  forgot_password: `পাসওয়ার্ড ভুলে গেছেন`,
  confirm: `নিশ্চিত করুন`,
  dont_have_account: `একটি ব্যবহারকারী অ্যাকাউন্ট নেই?`,
  contact_us: `যোগাযোগ করুন`,
  game_menu: `গেম মেনু`,
  bonus: 'বোনাস',
  no_data: `কোন ডেটা নেই`,
  terms_and_condition: 'বিধি - নিষেধ এবং শর্তাবলী',
  game_list: 'খেলার তালিকা',
  play: 'খেলা',
  games: 'গেমস',
  casino: 'ক্যাসিনো',
  slots: 'স্লট',
  card: 'কার্ড',
  fishing: 'মাছ ধরা',
  sport: 'খেলা',
  sports: 'খেলা',
  bonuses: 'বোনাস',
  account_profile: `অ্যাকাউন্ট প্রোফাইল`,
  email: `ইমেইল`,
  full_name: `পুরো নাম`,
  phone_number: `ফোন নম্বর`,
  level: `স্তর`,
  bank_list: `ব্যাংক তালিকা`,
  in_maintenance: `রক্ষণাবেক্ষণে`,
  birthday: `জন্মদিন`,
  birthday_bonus: `বোনাসের জন্য আপনার জন্মদিন লিখুন! 🎉🎁`,
  referral_code: `রেফারেল কোড`,
  invalid_x: `অবৈধ {{x}}`,
  extra: `অতিরিক্ত`,
  referral: `সুপারিশ`,
  my_referral: `আমার রেফারেল`,
  my_claim: `আমার দাবি`,
  claim_profit: `লাভ দাবি`,
  available_profit: `উপলব্ধ লাভ`,
  claimed_profit: `Claimed Profit`,
  all_time_profit: `সর্বকালের লাভ`,
  referral_note_on_x: `প্রতিবার আপনার বন্ধুরা {{x}} এ খেলে লাভ উপার্জন করুন`,
  tier: `স্তর`,
  commission: `কমিশন`,
  referred_member: `উল্লেখিত সদস্য`,
  profit_earned: `লাভ অর্জিত`,
  account_information: `হিসাবের তথ্য`,
  invite_friends: `বন্ধুদের আমন্ত্রণ জানান`,
  how_it_work: `এটি কিভাবে কাজ করে?`,
  invite_note: `আপনার বন্ধু আপনার রেফারেল কোড ব্যবহার করে সাইন আপ করলে একচেটিয়া রেফারেল বোনাস পান!`,
  share_via: `এর মাধ্যমে শেয়ার করুন`,
  referral_link: `আমার স্নাতকের`,
  total_profit: `সমস্ত লাভ`,
  copied: `কপি করা হয়েছে`,
  nothing_to_claim: `দাবি করার কিছুই নেই`,
  claim_history: `দাবি ইতিহাস`,
  from: `থেকে`,
  to: `প্রতি`,
  choose_date: `তারিখ নির্বাচন করুন`,
  select_both_date: `তারিখ থেকে এবং থেকে উভয়ই নির্বাচন করুন`,
  hello: 'হ্যালো',
  my_downline: `আমার ডাউনলাইন`,
  downline: `ডাউনলাইন`,
  forgot_password_contact:
    'আপনার পাসওয়ার্ড পরিবর্তন করতে সাহায্য করার জন্য অনুগ্রহ করে নীচে দেওয়া গ্রাহক সহায়তার সাথে যোগাযোগ করুন৷',
  submit: 'জমা দিন',
  current_password: 'বর্তমান পাসওয়ার্ড',
  new_password: 'নতুন পাসওয়ার্ড',
  confirm_new_password: 'নিশ্চিত কর নতুন গোপননম্বর',
  already_have_an_account: 'ইতিমধ্যে একটি সদস্যপদ আছে?',
  share: 'শেয়ার করুন',
  more_info: 'অধিক তথ্য',
  copy_link: 'লিংক কপি করুন',
  share_to_friend: 'বন্ধুকে শেয়ার করুন',
  get_bonus: 'বোনাস পান',
  copy_your_referral:
    'আপনার রেফারেল লিঙ্ক কপি করুন এবং সোশ্যাল মিডিয়াতে পাঠান এবং আপনার ডাউনলাইন থেকে ক্রেডিট উপার্জন করুন!',
  last_x_digit_bank_ref_number: `ব্যাঙ্ক রেফ নম্বরের শেষ {{x}} সংখ্যা`,
  bank_ref_number_validation_x: `ব্যাঙ্ক রেফ নম্বর প্রয়োজন এবং অবশ্যই {{x}} অক্ষর দৈর্ঘ্য হতে হবে৷`,
  card_game: 'কার্ড খেলা',
  e_sports: 'ই-স্পোর্টস',
  instant_game: 'তাত্ক্ষণিক খেলা',
  sort: 'সাজান',
  name: 'নাম',
  default: 'ডিফল্ট',
  e_sports_bonus: 'ই-স্পোর্টস বোনাস',
  fishing_bonus: 'মাছ ধরা বোনাস',
  casino_bonus: 'ক্যাসিনো বোনাস',
  sports_bonus: 'খেলা বোনাস',
  slots_bonus: 'স্লট বোনাস',
  instant_game_bonus: 'তাত্ক্ষণিক খেলা বোনাস',
  card_game_bonus: 'কার্ড খেলা বোনাস',
  all_bonus: 'সব বোনাস',
  how_to_get_referral_bonus: 'রেফারাল বোনাস পাওয়ার উপায়',
  steps_to_claim: 'বোনাস দাবির পদক্ষেপ',
  share_your_referral_code:
    '1. ভাইবার, লাইন, টেলিগ্রাম, হোয়াটসঅ্যাপ, ফেসবুক বা মেসেঞ্জারের মাধ্যমে অ্যাকাউন্ট নিবন্ধন করতে আপনার রেফারেল কোড আপনার বন্ধুদের সাথে শেয়ার করুন।',
  after_friend_register:
    '2. বন্ধু রেজিস্টার করার পর, New365 এর সাথে নিজেকে এবং রেফারাল সদস্যকে এই বোনাস দাবি করতে অবশ্যই ডিপোজিট করতে হবে।',
  refer_a_friend_bonus: '3. রেফার বনাস হিসাব:',
  turnover_played: 'খেলা টার্নওভার x কমিশন % = রেফারাল বনাস',
  example: 'উদাহরণ:',
  turnover_formula_x: 'টার্নওভার (1,000,000) x 0.001% (কমিশন %) = 1,000{{x}}',
  note_bonus_only: '* নোট: বোনাসটি শুধুমাত্র রাত 12:00 টা পর প্রাপ্ত করা যাবে',
  note_bonus_only_x: '*নোট: বোনাসটি শুধুমাত্র পরবর্তী দিনে {{x}} পরে দাবি করা যাবে',
  access_dashboard: '4. লাভযোগ্য লাভ, কমিশন টিয়ার, দাবি ইতিহাস এবং ডাউনলাইন চেক করতে ড্যাশবোর্ড অ্যাক্সেস করুন।',
  click_this_button_to_claim: 'রেফারাল বনাস দাবি করতে এই বাটনটি ক্লিক করুন',
  commision_tier: 'কমিশন টিয়ার',
  member_downline: 'সদস্য ডাউনলাইন',
  bonus_tutorial_video: 'বোনাস টিউটোরিয়াল ভিডিও',
  popularity: 'জনপ্রিয়তা',
  invalid_captcha: 'অবৈধ ক্যাপচা',
  captcha: 'ক্যাপচা',
  claim_bonus: 'বোনাস দাবি করুন',
  claimed: 'দাবি করেছে',
  bonus_claimed: 'বোনাস দাবি করা হয়েছে',
  online_banking: 'অনলাইন ব্যাংকিং',
  please_enter_email: 'পাসওয়ার্ড রিসেট প্রক্রিয়ার সাথে এগিয়ে যেতে আপনার ইমেল ঠিকানা লিখুন',
  please_select_a_bank: 'একটি ব্যাঙ্ক নির্বাচন করুন',
  deposit_from_bank: 'ব্যাংক থেকে জমা',
  from_selected_promotion: 'নির্বাচিত প্রচার থেকে',
  from_selected_bank: 'নির্বাচিত ব্যাংক থেকে',
  no_balance: 'এগিয়ে যাওয়ার জন্য আপনার যথেষ্ট ব্যালেন্স নেই',
  sign_up: 'নিবন্ধন করুন',
  refer_and_reward: 'উল্লেখ করুন এবং পুরস্কার',
  minor_note: `এই সাইট পরিদর্শন করতে, নিশ্চিত করুন যে আপনি 18 বছরের বেশি এবং সম্মত হন`,
  terms_of_service: 'সেবা পাবার শর্ত',
  confirm_18: `আমি নিশ্চিত যে আমার বয়স 18 বছর এবং আমি পড়েছি`,
  this_field: 'এই মাঠ',
  slots_description: `স্লট মেশিনে সাধারণত তিনটি বা ততোধিক "রিল" থাকে, যার প্রতিটিতে একটি অসাড় থাকে...`,
  play_now: `এখন খেলুন`,
  live_casino: `লাইভ ক্যাসিনো`,
  promotion: `পদোন্নতি`,
  profile: `প্রোফাইল`,
  wallet: `ওয়ালেট`,
  promotions: 'পদোন্নতি',
  cock_fighting: 'কক লড়াই',
  home: 'হোম',
  // TODO: Translate to OTHER LANG
  reset_password: 'পাসওয়ার্ড রিসেট করুন',
  manage_your_bank_accounts: `আপনার ব্যাংক অ্যাকাউন্টগুলি পরিচালনা করুন`,
  history_of_deposit_withdraw_transactions: `জমা/উত্তোলন লেনদেনের ইতিহাস`,
  transaction_type: 'লেনদেনের প্রকার',
  period_history: 'মৌলিক ইতিহাস',
  reset: 'রিসেট',
  learn_more: `আরও জানুন`,
  number_exists: 'এই ফোন নম্বরটি ইতিমধ্যে নিবন্ধিত!',
  number_not_exists: 'এই ফোন নম্বরটি নিবন্ধিত নয়!',
  optional: `ঐচ্ছিক`,
  app_download: `অ্যাপ ডাউনলোড`,
  payment_draft:
    'আপনি যদি নিশ্চিত হন যে আপনি ইতিমধ্যেই অর্থপ্রদান করেছেন, অনুগ্রহ করে কিছুক্ষণ অপেক্ষা করুন কারণ অর্থপ্রদান এখনও প্রক্রিয়াধীন হতে পারে',
  be_a_vip: `ভিআইপি হন`,
  experience_exlusivity: `অনন্য এক্সক্লুসিভিটি অভিজ্ঞতা বিশেষ প্রচার, উপহার এবং রিবেট - আপনার প্রত্যাশার চেয়ে বেশি!`,
  become_vip1: `মালয়েশিয়ার সবচেয়ে উদ্ভাবনী এবং সর্বব্যাপী অনলাইন গেমিং ওয়েবসাইট হিসেবে, NEW365 সদস্যদের জন্য সেরা দিতে কখনই থামে না। গেম অপশন থেকে সেবা মান পর্যন্ত, NEW365 সর্বশেষ এবং সেরা প্রদান করে।`,
  become_vip2: `একটি স্তরভিত্তিক অভিজ্ঞতা উন্নত করার জন্য, NEW365 একটি এক্সক্লুসিভ VIP প্রোগ্রাম প্রবর্তন করছে যা বিশ্বস্ত সদস্যদের আমাদের বিনোদন প্রদানকারী হিসেবে আরও বেশি বিশ্বাস রাখতে উদ্বুদ্ধ করবে। NEW365 VIP প্রোগ্রামের মৌলিক পাঁচটি স্তর হলো ব্রোঞ্জ, সিলভার, গোল্ড, প্লাটিনাম এবং ডায়মন্ড। একজন NEW365 VIP সদস্য হিসেবে, আপনি পাবেন এক্সক্লুসিভ অফার, উপহার, এবং বোনাস এবং অগ্রাধিকার সেবা - একটি চমৎকার গেমিং অভিজ্ঞতার জন্য প্রয়োজনীয় সবকিছু। বিশেষ রেট এবং স্তর আপগ্রেড বোনাস আপনার গেমিং লক্ষ্যে পৌঁছানোর জন্য প্রয়োজনীয় সবকিছু দিতে পারে!`,
  become_vip3: `একটি স্তরভিত্তিক প্রোগ্রামের উদ্দেশ্য হলো সদস্যদেরকে ধাপে ধাপে উন্নীত হওয়ার জন্য প্রয়োজনীয় প্রেরণা প্রদান করা। যদিও আমরা সদস্যদের VIP স্তরের প্রয়োজনীয়তা পূরণ করে অগ্রসর হতে উত্সাহিত করি, আমাদের মনোযোগ সবসময় সদস্যদের সন্তুষ্টিতে, বিশেষ করে VIP সদস্যদের উপর। কারণ আপনার সন্তুষ্টি আমাদের প্রধান উদ্বেগ।`,
  to_all_member: `প্রথমবারের মতো আপগ্রেড করা সকল সদস্যদের জন্য`,
  vip_journey1: `1. নিয়মিত সদস্যরা নির্ধারিত সময়সীমার মধ্যে ডিপোজিটের প্রয়োজনীয়তা পূরণের পর স্বয়ংক্রিয়ভাবে VIP সদস্য হয়ে উঠবেন।`,
  vip_journey2: `2. সিস্টেমকে 2 কর্মদিবসের মধ্যে আপনার তথ্য যাচাই এবং আপডেট করতে দিন।`,
  vip_journey3: `3. আরাম করুন, শিথিল হন এবং আপনার VIP পুরস্কার উপভোগ করতে শুরু করুন!`,
  benefits_as_our_vip: `আমাদের VIP হিসেবে সুবিধাসমূহ`,
  vip_upgrading1: `আপনার নিরাপত্তা এবং সুরক্ষার জন্য, আপনাকে NEW365 VIP আপগ্রেডিং নীতিতে উল্লেখিত সমস্ত প্রয়োজনীয় বিস্তারিত তথ্য প্রদান করতে হবে। আমাদের দলের তথ্য যাচাই এবং পর্যালোচনা করতে সর্বোচ্চ 2 কার্যদিবস সময় লাগবে। তারপর, আমরা আমাদের সিস্টেমে আপনার সর্বশেষ অবস্থা এবং তথ্য আপডেট করব, এবং এরপর আপনার VIP সদস্য হিসেবে যাত্রা শুরু হবে!`,
  vip_upgrading2: `রূপান্তরটি মসৃণ এবং দক্ষভাবে সম্পন্ন হতে নিশ্চিত করার জন্য, দয়া করে সমস্ত আপগ্রেডিং বিধিমালা মেনে চলুন। সহায়তার প্রয়োজন হলে আমাদের গ্রাহক সেবা প্রতিনিধিদের সাথে যোগাযোগ করতে দ্বিধা করবেন না।`,
  no_data_available: `তথ্য উপলব্ধ নয়`,
  how_does_it_work: `এটি কিভাবে কাজ করে?`,
  new_vip_journey: `NEW365 VIP যাত্রা`,
  vip_benefits: `VIP সুবিধাসমূহ`,
  faq: `প্রশ্ন`,
  membership_renewal: `সদস্যপদ নবায়ন`,
  about: `সম্পর্কিত`,
  upgrade: `আপগ্রেড`,
  benefits: `সুবিধা`,
  special_rebate: `বিশেষ রিবেট`,
  join_now: 'এখন যোগ দিন',
  upi_id: `UPI ID`,
  enter_upi_id: `লিখুন UPI ID`,
  please_download_x_to_play: 'খেলার জন্য {{x}} ডাউনলোড করুন',
  copy: 'কপি করুন',
  download: 'ডাউনলোড করুন',
  please_enter_fullname_as_bank:
    'আপনার পুরো নাম লিখুন. আপনার ব্যাঙ্ক অ্যাকাউন্ট ধারকের নামের মতই হতে হবে নতুবা আমানত প্রত্যাখ্যান করা হবে।',
  update_kyc: 'केवाईसी अपडेट करें',
  kyc_verification: 'केवाईसी सत्यापन',
  ic_number: 'आईसी नंबर',
  nid_number: 'एनआईडी नंबर',
  ic_front: 'आईसी फ्रंट',
  nid_front: 'एनआईडी फ्रंट',
  selfie_video_with_ic: 'आईसी के साथ सेल्फी वीडियो',
  selfie_video_with_nid: 'एनआईडी के साथ सेल्फी वीडियो',
  kyc_submitted: 'केवाईसी सबमिट किया गया',
  file_too_large: 'फाइल बहुत बड़ी है। अधिकतम आकार {{x}} एमबी की अनुमति है।',
  limit_your_vid_to_5: 'अपना वीडियो 5 सेकंड तक सीमित करें',
  camera_access_denied: 'कैमरा एक्सेस अस्वीकृत। कृपया ब्राउज़र सेटिंग्स में कैमरा अनुमतियां सक्षम करें।',
  camera_not_available: 'कोई कैमरा नहीं मिला। कृपया कैमरा कनेक्ट करें या अपने डिवाइस की सेटिंग्स जांचें।',
  selfie_photo_with_nid: 'एनआईडी के साथ सेल्फी फोटो',
  selfie_photo_with_ic: 'আইসির সাথে সেলফি',
  enter_text_on_the_left: 'बाईं ओर का टेक्स्ट दर्ज करें',
  otp_sent_enter_code_password: 'ओटीपी भेजा गया! कृपया कोड और अपना नया पासवर्ड दर्ज करें।',
  popular: 'জনপ্রিয়',
  hot_games: 'হট গেমস',
  aadhaar_card_number: 'আধার কার্ড নম্বর',
  selfie_with_aadhaar_card: 'আধার কার্ডসহ সেলফি',
  aadhaar_card_front: 'আধার কার্ডের সামনের অংশ',
  please_scan_using_your_x_app: '{{x}} অ্যাপ ব্যবহার করে স্ক্যান করুন',
  // TODO: translate to other languages
};

export default bn;
